import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { UsurpFiltersDrawer } from 'site-modules/shared/components/inventory/usurp-filters-drawer/usurp-filters-drawer';
import { SemanticSearchDrawerContentAsync } from 'site-modules/shared/components/inventory/semantic-search-drawer/semantic-search-drawer-content-async';
import { SemanticSearchForm } from 'site-modules/shared/components/inventory/semantic-search-form/semantic-search-form';
import { SemanticSearchModel } from 'client/data/models/semantic-search';
import { connectToModel } from 'client/data/luckdragon/redux/react-binding';

import './semantic-search.scss';

function SemanticSearchUI({
  setModelValue,
  onFiltersBtnClick,
  onFiltersUpdate,
  isMobile,
  YMMTPlaceholder,
  dynamicPlaceholderSuggestions,
  isSticky,
  submitButtonContent,
  searchFormClassName,
  inputClassName,
  inputMinHeight,
  submitButtonClassName,
  disableDrawerCloseOnSubmit,
  isHome,
  dealerId,
  searchRoute,
  autosizeOnFocus,
  ariaLabel,
}) {
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [isLlmDrawerOpen, setIsLlmDrawerOpen] = useState(false);

  const openLlmDrawer = useCallback(
    async srpQuery => {
      await setModelValue('userSearch', SemanticSearchModel, srpQuery);
      setIsLlmDrawerOpen(true);
    },
    [setModelValue]
  );

  const closeLlmDrawer = useCallback(() => {
    setIsLlmDrawerOpen(false);
  }, []);

  const handleErrorScreenCancel = useCallback(async () => {
    await setModelValue('userSearch', SemanticSearchModel, '');
    closeLlmDrawer();
  }, [closeLlmDrawer, setModelValue]);

  const handleApplyFilters = useCallback(async () => {
    closeLlmDrawer();
    await setModelValue('userSearch', SemanticSearchModel, '');
    setFiltersApplied(true);
  }, [closeLlmDrawer, setModelValue]);

  return (
    <>
      <SemanticSearchForm
        YMMTPlaceholder={YMMTPlaceholder}
        dynamicPlaceholderSuggestions={dynamicPlaceholderSuggestions}
        isSticky={isSticky}
        filtersApplied={filtersApplied}
        openLlmDrawer={openLlmDrawer}
        isLlmDrawerOpen={isLlmDrawerOpen}
        submitButtonContent={submitButtonContent}
        searchFormClassName={searchFormClassName}
        inputClassName={inputClassName}
        inputMinHeight={inputMinHeight}
        submitButtonClassName={submitButtonClassName}
        searchRoute={searchRoute}
        isHome={isHome}
        autosizeOnFocus={autosizeOnFocus}
        isMobile={isMobile}
        ariaLabel={ariaLabel}
      />
      <UsurpFiltersDrawer
        isOpen={isLlmDrawerOpen}
        onCancel={closeLlmDrawer}
        onErrorScreenCancel={handleErrorScreenCancel}
        onApplyFilters={handleApplyFilters}
        onApply={noop}
        hideFiltersButton={isHome}
        drawerContentComponent={SemanticSearchDrawerContentAsync}
        drawerContainerClasses="d-flex"
        drawerContentClasses="w-100"
        isMobile={isMobile}
        onFiltersBtnClick={onFiltersBtnClick}
        onFiltersUpdate={onFiltersUpdate}
        YMMTPlaceholder={YMMTPlaceholder}
        dynamicPlaceholderSuggestions={dynamicPlaceholderSuggestions}
        filtersApplied={filtersApplied}
        openLlmDrawer={openLlmDrawer}
        closeLlmDrawer={closeLlmDrawer}
        isLlmDrawerOpen={isLlmDrawerOpen}
        disableDrawerCloseOnSubmit={disableDrawerCloseOnSubmit}
        dealerId={dealerId}
        searchRoute={searchRoute}
        withResearchLinks={isHome}
      />
    </>
  );
}

SemanticSearchUI.propTypes = {
  setModelValue: PropTypes.func.isRequired,
  onFiltersBtnClick: PropTypes.func,
  onFiltersUpdate: PropTypes.func,
  YMMTPlaceholder: PropTypes.string,
  dynamicPlaceholderSuggestions: PropTypes.arrayOf(PropTypes.string),
  isMobile: PropTypes.bool,
  isHome: PropTypes.bool,
  isSticky: PropTypes.bool,
  submitButtonContent: PropTypes.node,
  searchFormClassName: PropTypes.string,
  inputMinHeight: PropTypes.number,
  inputClassName: PropTypes.string,
  submitButtonClassName: PropTypes.string,
  disableDrawerCloseOnSubmit: PropTypes.bool,
  dealerId: PropTypes.string,
  searchRoute: PropTypes.string,
  autosizeOnFocus: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

SemanticSearchUI.defaultProps = {
  onFiltersBtnClick: noop,
  onFiltersUpdate: noop,
  YMMTPlaceholder: '',
  dynamicPlaceholderSuggestions: [],
  isMobile: false,
  isHome: false,
  isSticky: false,
  searchFormClassName: undefined,
  submitButtonContent: undefined,
  inputMinHeight: undefined,
  inputClassName: undefined,
  submitButtonClassName: undefined,
  disableDrawerCloseOnSubmit: undefined,
  dealerId: undefined,
  searchRoute: undefined,
  autosizeOnFocus: undefined,
  ariaLabel: undefined,
};

export const SemanticSearch = connectToModel(SemanticSearchUI, {});
