import { map } from 'lodash';

const RATING_TEXT_TO_NUMBER = {
  oneStarRatingCount: 1,
  oneStarRating: 1,
  twoStarRatingCount: 2,
  twoStarRating: 2,
  threeStarRatingCount: 3,
  threeStarRating: 3,
  fourStarRatingCount: 4,
  fourStarRating: 4,
  fiveStarRatingCount: 5,
  fiveStarRating: 5,
};

/**
 * Converts passed object that looks like:
    `{
      oneStarRatingCount: 341,
      twoStarRatingCount: 41,
      threeStarRatingCount: 31,
      fourStarRatingCount: 321,
      fiveStarRatingCount: 412,
    }`
 * to ordered array of objects where first rating item is five star rating:
    `[
      { number: 5, value: 412 },
      { number: 4, value: 321 },
      { number: 3, value: 31 },
      { number: 2, value: 41 },
      { number: 1, value: 341 },
    ]`
 * @param {Object} ratings
 * @returns {Object[]}
 */
export const convertRatings = ratings =>
  map(ratings, (value, key) => ({ number: RATING_TEXT_TO_NUMBER[key], value })).reverse();
