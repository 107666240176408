import { get } from 'lodash';
import { PUB_STATES_LOWERCASE } from 'client/constants/pub-states';
import { DEALERLESS_OEM } from 'site-modules/shared/constants/oem';

export const DEALERLESS_OEM_MSG_TYPE = {
  NEW_ONLY: 'new-only',
  INCLUDES_USED: 'includes-used',
};

export const isNoInventoryPorscheMessage = ({ make, isNewPageState, facets }) => {
  const inventoryTypeFacet = facets.find(({ type }) => type === 'inventoryType');
  const newInventoryFacet = get(inventoryTypeFacet, 'values', []).find(
    ({ value }) => value === PUB_STATES_LOWERCASE.NEW
  );
  const newInventoryCount = get(newInventoryFacet, 'count', 0);

  return make === 'Porsche' && isNewPageState && newInventoryCount === 0;
};

export const getNoInventoryDealerlessMessageType = ({ makeSlug, selectedCarTypes }) => {
  if (
    !DEALERLESS_OEM.includes(makeSlug) ||
    (selectedCarTypes.length && !selectedCarTypes.includes(PUB_STATES_LOWERCASE.NEW))
  ) {
    return null;
  }

  return selectedCarTypes.length === 1 && selectedCarTypes[0] === PUB_STATES_LOWERCASE.NEW
    ? DEALERLESS_OEM_MSG_TYPE.NEW_ONLY
    : DEALERLESS_OEM_MSG_TYPE.INCLUDES_USED;
};
