import React, { useState, useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { ZipLongDistanceConfirmContext } from 'site-modules/shared/components/lead-form/zip-long-distance-confirm/zip-long-distance-confirm-context';
import { isDefaultZipCode } from 'site-modules/shared/utils/inventory/is-default-zip-code';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { VisitorModel } from 'client/data/models/visitor';

export const ZipLongDistanceConfirmProviderUI = props => {
  const { children, isUserSetZip, zipCode } = props;
  const isZipFormEnabled = useRef(!isUserSetZip && isDefaultZipCode(zipCode));
  const [showZipLongDistanceConfirm, setZipLongDistanceConfirm] = useState(isZipFormEnabled.current);

  const handleSetZipLongDistanceConfirm = useCallback(() => {
    setZipLongDistanceConfirm(false);
  }, [setZipLongDistanceConfirm]);

  const value = useMemo(
    () => ({
      showZipLongDistanceConfirm,
      setZipLongDistanceConfirm: handleSetZipLongDistanceConfirm,
    }),
    [showZipLongDistanceConfirm, handleSetZipLongDistanceConfirm]
  );

  return isZipFormEnabled.current ? (
    <ZipLongDistanceConfirmContext.Provider value={value}>{children}</ZipLongDistanceConfirmContext.Provider>
  ) : (
    children
  );
};

ZipLongDistanceConfirmProviderUI.propTypes = {
  children: PropTypes.node.isRequired,
  isUserSetZip: PropTypes.bool,
  zipCode: PropTypes.string.isRequired,
};

ZipLongDistanceConfirmProviderUI.defaultProps = {
  isUserSetZip: false,
};

const stateToPropsConfig = {
  zipCode: bindToPath('location.zipCode', VisitorModel),
  isUserSetZip: bindToPath('location.userSet', VisitorModel),
};

export const ZipLongDistanceConfirmProvider = connectToModel(ZipLongDistanceConfirmProviderUI, stateToPropsConfig);
