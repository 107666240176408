import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { LEAD_FORMS_CREATIVE_ID } from 'site-modules/shared/components/lead-form/unified-lead-form/constants/creative-ids';
import { getLeadFormCreativeId } from 'site-modules/shared/components/lead-form/utils/lead-form-creative-id';

import { PricingCarouselProgressive } from './pricing-carousel-progressive';

export function PricingCarouselFooterUI(props) {
  const { pageName, isAdRendered, dataTrackingLeadForm } = props;
  const dataTracking =
    dataTrackingLeadForm ||
    getLeadFormCreativeId({
      isNewVin: true,
      pageNameLegacy: pageName,
      formName: LEAD_FORMS_CREATIVE_ID.FOOTER_PRICING_MODULE,
    });

  return (
    <PricingCarouselProgressive
      {...props}
      dataTrackingParent={`new-footer_pricing_module-${pageName}`}
      cardWrapperClassName="core-pricing-card"
      isAdRendered={isAdRendered}
      showLeadForm
      leadFormOptions={{
        ...props.leadFormOptions,
        creativeId: dataTracking,
        dataTrackingParent: dataTracking,
      }}
      pricingAdPos={'9'}
    />
  );
}

export const mapStateToProps = state => ({
  pageName: get(state, 'pageContext.legacy.pageName') || get(state, 'pageContext.page.name'),
});

export const PricingCarouselFooter = connect(mapStateToProps)(PricingCarouselFooterUI);
