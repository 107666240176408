import { find, get, orderBy } from 'lodash';
import { MAKE, MODEL, YEAR } from 'site-modules/shared/constants/allowed-inventory-request-params';
import { isUsed as isUsedType } from 'site-modules/shared/utils/inventory-utils/is-used';
import { getMakeModelLinkUrl, getMakeModelYearLinkUrl } from 'site-modules/shared/utils/vehicle-link-constructor';
import { isUsed as isUsedPubState, isUsedState } from 'site-modules/shared/utils/publication-states';
import { isComboPageYear } from 'site-modules/shared/utils/inventory/srp-review-combo';

export const pickValues = (type, facets) => find(facets, { type }) || {};

/**
 * Get MMY Link or MM link,
 * @param {String} makeSlug
 * @param {String} modelSlug
 * @param {String} year
 * @param {Boolean} isUsedModel
 * @returns {String}
 */
export const getLink = ({ makeSlug, modelSlug, year, isUsedModel }) =>
  isUsedModel
    ? getMakeModelYearLinkUrl({ makeSlug, modelSlug, year }, isComboPageYear(year) ? '' : `review/`)
    : getMakeModelLinkUrl({ makeSlug, modelSlug });

/**
 * Get data for getLink() from facets and inventory
 * @param {Object[]} facets
 * @param {Array} inventory
 * @returns {Object}
 */
export const getLinkDataFromInventory = ({ facets, inventory }) => {
  const { name: makeName, value: makeSlug } = get(pickValues(MAKE, facets), 'values[0]', {});
  const { name: modelName, value: modelSlug } = get(pickValues(MODEL, facets), 'values[0]', {});
  if (modelName) {
    const year = get(pickValues(YEAR, facets), 'max', '');
    if (year && inventory.length) {
      const vehicle = orderBy(inventory, ['vehicleInfo.styleInfo.year'], ['desc'])[0];
      const vehicleYear = get(vehicle, 'vehicleInfo.styleInfo.year');
      const type = get(vehicle, 'type');
      return {
        make: { name: makeName, slug: makeSlug },
        model: { name: modelName, slug: modelSlug },
        year: vehicleYear,
        isUsedModel: isUsedType(type),
      };
    }
    return {
      make: { name: makeName, slug: makeSlug },
      model: { name: modelName, slug: modelSlug },
      year: '',
      isUsedModel: false,
    };
  }
  return null;
};

/**
 * Get data for getLink() from vehicle
 * @param {Object} vehicle
 * @returns {Object}
 */
export const getLinkDataFromVehicle = vehicle => {
  const vehiclePubStates = get(vehicle, 'pubStates');
  return {
    make: get(vehicle, 'make'),
    model: get(vehicle, 'model'),
    year: get(vehicle, 'year') || get(vehicle, 'modelYear.year'),
    isUsedModel: vehiclePubStates ? isUsedPubState(vehiclePubStates) : isUsedState(get(vehicle, 'publicationState')),
  };
};
