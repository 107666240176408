import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { useModalFocusTrap } from 'site-modules/shared/hooks/use-modal-focus-trap';

import './usurp-error-reload-button.scss';

const WIDGET_NAME = 'edm-entry-facets';

export function UsurpErrorReloadButton(props) {
  const { children } = props;
  const stickyButtonContainerRef = useRef(null);

  useModalFocusTrap(stickyButtonContainerRef);

  useEffect(() => {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        subaction_name: TrackingConstant.SUBACTION_SRP_ERROR,
        action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
        widget_name: WIDGET_NAME,
      },
    });
  }, []);

  const handleClick = useCallback(e => {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.VIEW_SEARCH_RESULT,
        subaction_name: TrackingConstant.SUBACTION_SRP_RETRY_SEARCH_RESULTS,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
        widget_name: WIDGET_NAME,
        value: e.target.innerText,
      },
    });
  }, []);

  return (
    <div ref={stickyButtonContainerRef} className="usurp-error-reload-button">
      <Button tag="a" href="" onClick={handleClick} color="primary-b">
        {children}
      </Button>
    </div>
  );
}

UsurpErrorReloadButton.propTypes = {
  children: PropTypes.node.isRequired,
};
