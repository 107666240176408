import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'site-modules/shared/components/link/link';

import './year-link.scss';

const LINK_CLASS_NAME = 'year-btn px-1 py-0_5 bg-white';

export function YearLink({ year, url, currentYear, skipCurrentYear, noFollow, noSpeculation, trackingId }) {
  return (
    <li className="year-link d-flex">
      {skipCurrentYear && year === currentYear ? (
        <div className={classnames(LINK_CLASS_NAME, 'year-current font-weight-medium text-primary-darker')}>{year}</div>
      ) : (
        <Link
          to={url}
          className={LINK_CLASS_NAME}
          rel={noFollow ? 'nofollow' : undefined}
          data-tracking-id={trackingId || 'see_other_years'}
          {...(noSpeculation ? { 'data-no-speculation': '' } : {})}
        >
          {year}
        </Link>
      )}
    </li>
  );
}

YearLink.propTypes = {
  year: PropTypes.string,
  url: PropTypes.string,
  currentYear: PropTypes.string,
  trackingId: PropTypes.string,
  skipCurrentYear: PropTypes.bool,
  noFollow: PropTypes.bool,
};

YearLink.defaultProps = {
  year: null,
  url: null,
  currentYear: null,
  trackingId: null,
  skipCurrentYear: true,
  noFollow: false,
};
