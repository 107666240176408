import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { TrackingConstant } from 'client/tracking/constant';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { get } from 'lodash';
import {
  buildDealerIcoWidgetConfigurationPath,
  DealerIcoWidgetEntities,
  DealerIcoWidgetModel,
} from 'client/data/models/dealer-ico-widget';
import { IcoWidget } from 'client/site-modules/shared/components/dealer-ico-widget/ico-widget/ico-widget';
import { ICO_WIDGET_DISPLAY_TYPES } from 'site-modules/shared/components/dealer-ico-widget/constants';
import { getStaticImageUrl } from 'client/utils/image-helpers';

import './ico-edmunds-theme-trigger.scss';

export function IcoEdmundsThemeTriggerUI({ dealerName, dealerId, icoWidgetConfig, isMobile }) {
  const isWidgetActive = get(icoWidgetConfig, 'isActive', false);
  return (
    isWidgetActive && (
      <Fragment>
        <div
          data-tracking-parent={TrackingConstant.CREATIVE_ID_EDM_ENTRY_ICO_REDUCED_HEIGHT_STANDARD_EDMUNDS_THEME}
          className="ico-edmunds-theme-trigger bg-white p-1 mb-1 w-100 d-flex flex-column flex-lg-row justify-content-between align-items-center text-center text-lg-left"
        >
          <div className="ico-edmunds-theme-trigger__title">
            <h2 className="text-cool-gray-10 size-20 mb-0_5">What&apos;s my car worth?</h2>
            <h3 className="text-cool-gray-40 size-14 m-0">
              Get an instant cash offer from <br className="d-lg-none" />{' '}
              <span className="text-blue-40">{dealerName}</span> today!
            </h3>
          </div>
          <button
            type="button"
            className={classNames(
              'ico-edmunds-theme-trigger__cta-button edmunds-instant-offer-trigger border-0 bg-blue-50 text-white py-0_5 px-1 my-1 mx-0 my-lg-0 font-weight-medium',
              { 'w-100 ico-edmunds-theme-trigger__cta-button-mobile': isMobile }
            )}
          >
            <span> Get Cash Offer Now </span>
          </button>
          <div className="ico-edmunds-theme-trigger__footnote d-flex flex-column">
            <span className="text-cool-gray-40 mb-0_25 size-10">Powered by </span>
            <img src={getStaticImageUrl('/img/inventory/svg/logo-brand-primary.svg')} alt="edmunds" loading="lazy" />
          </div>
        </div>
        <IcoWidget
          dealerId={dealerId}
          displayType={ICO_WIDGET_DISPLAY_TYPES.REDUCED_HEIGHT_STANDARD_EDMUNDS_THEME}
          injectInitializationScriptOnMount
          showSpinner={false}
        />
      </Fragment>
    )
  );
}

IcoEdmundsThemeTriggerUI.propTypes = {
  dealerName: PropTypes.string,
  dealerId: PropTypes.string.isRequired,
  icoWidgetConfig: DealerIcoWidgetEntities.Configs,
  isMobile: PropTypes.bool.isRequired,
};

IcoEdmundsThemeTriggerUI.defaultProps = {
  dealerName: '',
  icoWidgetConfig: {},
};

export const stateToPropsConfig = {
  icoWidgetConfig: bindToPath(
    ({ dealerId }) => buildDealerIcoWidgetConfigurationPath({ rooftopId: dealerId }),
    DealerIcoWidgetModel
  ),
};

export const IcoEdmundsThemeTrigger = connectToModel(IcoEdmundsThemeTriggerUI, stateToPropsConfig);
