import React from 'react';
import { InventoryEntities } from 'client/data/models/inventory';
import { getDeliveryTypeTitle } from 'site-modules/shared/components/delivery/helper';
import { getIsStockPhotoType } from 'client/site-modules/shared/utils/inventory-image-utils';

/**
 * Renders SRP disclaimer.
 * @param {object[]} inventory
 * @returns {ReactElement|null}
 */
export function SrpDisclaimer({ inventory }) {
  const showDeliveryDisclaimer = inventory.some(getDeliveryTypeTitle);
  const showStockPhotoDisclaimer = inventory.some(getIsStockPhotoType);
  const hasDisclaimers = showDeliveryDisclaimer || showStockPhotoDisclaimer;

  return hasDisclaimers ? (
    <div className="mt-2 text-cool-gray-40">
      {showDeliveryDisclaimer && (
        <p className="size-12">
          * Delivery of any kind does not apply to Alaska and Hawaii. Contact the dealer for delivery details,
          restrictions and costs.
        </p>
      )}
      {showStockPhotoDisclaimer && (
        <p className="size-12">
          ** This image is a stock photo and may not reflect the model year or color of, and/or equipment offered on,
          the advertised vehicle.
        </p>
      )}
    </div>
  ) : null;
}

SrpDisclaimer.propTypes = {
  inventory: InventoryEntities.InventoriesVin,
};

SrpDisclaimer.defaultProps = {
  inventory: [],
};
