import { Component } from 'react';
import PropTypes from 'prop-types';
import { connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { EventToolbox } from 'client/utils/event-toolbox';
import { VisitorModel } from 'client/data/models/visitor';
import { TrackingConstant } from 'client/tracking/constant';

export const LOCATION_DEFAULT_OPTIONS = {
  maximumAge: 5 * 60 * 1000, // property that tell the browser to use a recently obtained geolocation result
  timeout: 60 * 1000, // property that tell how long will the browser wait for a response from the user
  enableHighAccuracy: false, //  property that tell to use a high level of precision
};

export const TRACKING_VALUES = {
  ALLOW: 'allow_location_detection',
  REJECT: 'reject_location_detection',
  IGNORE: 'ignore_location_detection',
};

export const ERROR_CODES = {
  UNKNOWN: 0,
  REJECT: 1,
  TIMEOUT: 3,
};

/**
 * Returns default tracking values
 * where action_cause is page_load for ignore tracking value (since it fires before the native browser location prompt displays)
 * and action_cause value is button_click for allow and reject tracking values
 * @param value
 * @returns {{action_category: string, action_cause: string}}
 */
export function getTrackingDefaults(value) {
  return {
    action_category: TrackingConstant.USER_ACTION_CATEGORY,
    action_cause: value === TRACKING_VALUES.IGNORE ? 'page_load' : 'button_click',
  };
}

/**
 * Sets user ZIP code by latitude and longitude.
 */
export class GeoLocationUI extends Component {
  static propTypes = {
    setModelValue: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    options: PropTypes.shape({
      maximumAge: PropTypes.number,
      timeout: PropTypes.number,
      enableHighAccuracy: PropTypes.bool,
    }),
    tracking: PropTypes.shape({}),
  };

  static defaultProps = {
    onSuccess: () => {},
    onError: () => {},
    options: {},
    tracking: {},
  };

  componentDidMount() {
    this.processGeoUserLocation();
  }

  /**
   * Process user location position when user clicks allow or disallow in system prompt.
   */
  processGeoUserLocation = () => {
    if (navigator && navigator.geolocation) {
      this.fireDisplayLocationPromptTracking();
      navigator.geolocation.getCurrentPosition(this.handleGeoSuccess, this.handleGeoError, {
        ...LOCATION_DEFAULT_OPTIONS,
        ...this.props.options,
      });
    } else {
      this.handleGeoError({ code: ERROR_CODES.UNKNOWN });
    }
  };

  /**
   * Handles coordinates set if geolocation is available.
   * @param {Object} coords
   * @returns {Promise}
   */
  handleGeoSuccess = ({ coords }) => {
    const { onSuccess, onError, setModelValue } = this.props;
    const { latitude, longitude } = coords;

    this.fireLocationDetectionTracking(TRACKING_VALUES.ALLOW);

    return setModelValue('location', VisitorModel, { latitude, longitude })
      .then(location => onSuccess(location))
      .catch(error => {
        onError(error);
        return Promise.reject(error);
      });
  };

  /**
   * Handles error occurred when geolocation throws an error.
   * @param {Object} error
   * @param {number} error.code It can be:
   * - 0 - unknown error,
   * - 1 - permission denied,
   * - 2 - position unavailable (error response from location provider),
   * - 3 - timed out.
   */
  handleGeoError = error => {
    switch (error.code) {
      case ERROR_CODES.REJECT: {
        this.props.onError(error);
        this.fireLocationDetectionTracking(TRACKING_VALUES.REJECT);
        break;
      }
      case ERROR_CODES.TIMEOUT: {
        this.fireLocationDetectionTracking(TRACKING_VALUES.IGNORE);
        break;
      }
      default: {
        break;
      }
    }
  };

  /**
   * Fires tracking.
   * @param {string} value
   */
  fireLocationDetectionTracking = value => {
    EventToolbox.fireTrackAction({
      event_type: 'action_completed',
      event_data: {
        action_name: TrackingConstant.VIEW_SEARCH_RESULT,
        subaction_name: TrackingConstant.FILTER_SEARCH_RESULT,
        ...getTrackingDefaults(value),
        ...this.props.tracking,
        value,
      },
    });
  };

  fireDisplayLocationPromptTracking = () => {
    EventToolbox.fireTrackAction({
      event_type: 'action_completed',
      event_data: {
        action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
        action_cause: 'page_load',
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        subaction_name: TrackingConstant.DISPLAY_LOCATION_PROMPT,
      },
    });
  };

  render() {
    return null;
  }
}

export const GeoLocation = connectToModel(GeoLocationUI, {});
