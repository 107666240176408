import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import {
  buildForSaleYearsPath,
  buildMmyModelFamilyIdsPath,
  buildModelFamilyIdsModelYearsPath,
  VehicleEntities,
  VehicleModel,
} from 'client/data/models/vehicle-v2';
import { flow, isEmpty, uniqBy } from 'lodash';
import { YearLink } from 'site-modules/shared/components/core-page/year-link/year-link';
import {
  getFilteredModelYears,
  getForSaleLinks,
  getModelYearsForSale,
} from 'site-modules/shared/utils/core-page/model-year-helpers';

import './other-years-for-sale.scss';

function OtherYearsForSaleUI({ vehicle, modelYears, forSaleYears }) {
  const {
    make: { name: makeName, slug: makeSlug },
    model: { name: modelName, slug: modelSlug },
    year,
  } = vehicle;

  const filteredModelYears = useMemo(() => getFilteredModelYears(modelYears, modelName), [modelName, modelYears]);
  const yearsForSale = uniqBy(
    [...getForSaleLinks({ forSaleYears, makeSlug, modelSlug }), ...getModelYearsForSale(filteredModelYears)],
    'name'
  );

  if (!yearsForSale.length) {
    return null;
  }

  return (
    <div
      className="other-years-for-sale bg-cool-gray-100 rounded-12 px-1 pt-1 pb-1_5 my-1_5"
      data-tracking-parent="years-module"
    >
      <h3 className="mb-1_5 heading-5">
        {makeName} {modelName} for Sale
      </h3>

      <ul className="years-list-grid list-unstyled m-0">
        {yearsForSale.map(({ name, url, noFollow, trackingId }) => (
          <YearLink
            key={`year-${name}`}
            year={name}
            url={url}
            currentYear={year}
            skipCurrentYear={!forSaleYears?.includes(parseInt(year, 10))}
            trackingId={trackingId}
            noFollow={noFollow}
          />
        ))}
      </ul>
    </div>
  );
}

OtherYearsForSaleUI.propTypes = {
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
  modelYears: PropTypes.arrayOf(PropTypes.shape({})),
  forSaleYears: PropTypes.arrayOf(PropTypes.number),
};

OtherYearsForSaleUI.defaultProps = {
  modelYears: null,
  forSaleYears: null,
};

export const OtherYearsForSale = flow(
  component =>
    connectToModel(component, {
      modelYears: bindToPath(
        ({ modelFamilyIds }) => !isEmpty(modelFamilyIds) && buildModelFamilyIdsModelYearsPath(modelFamilyIds),
        VehicleModel
      ),
    }),
  component =>
    connectToModel(component, {
      modelFamilyIds: bindToPath(
        ({ vehicle }) =>
          buildMmyModelFamilyIdsPath({ make: vehicle.make.slug, model: vehicle.model.slug, year: vehicle.year }),
        VehicleModel
      ),
      forSaleYears: bindToPath(
        ({ vehicle }) =>
          buildForSaleYearsPath({ make: vehicle.make.slug, model: vehicle.model.slug, year: vehicle.year }),
        VehicleModel
      ),
    })
)(OtherYearsForSaleUI);
