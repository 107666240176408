import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import {
  VehicleModel,
  buildVehiclePath,
  buildDefaultVehiclePath,
  buildVehicleStylePath,
} from 'client/data/models/vehicle';
import { HTTP_NOT_FOUND } from 'client/utils/http-status';
import { updateVehicle, buildContextVehicleFromMakeModelSubmodelYear } from 'client/actions/vehicle';
import { isPreProd, isUsed as isUsedPubState } from 'site-modules/shared/utils/publication-states';

/**
 * A preloader that assists with updating/resolving multiple model paths so they are preloaded into redux,
 * while also validating that vehicle requested is valid.
 *
 * @see {@link ModelPreloader}
 *
 * @param {Object} store - The redux store where the model will be stored.
 * @param {Object} props
 * @param {Object} params.
 */
export function VehiclePreloader(store, props, { isSubpage } = { isSubpage: true }) {
  const preloader = new ModelPreloader(store, { debugId: 'vehicle-preloader.jsx' });

  const vehiclePath = props.params.year ? buildVehiclePath(props.params) : buildDefaultVehiclePath(props.params);

  preloader.resolve(vehiclePath, VehicleModel);

  if (props.params.styleId) {
    preloader.resolve(buildVehicleStylePath(props.params), VehicleModel);
  }

  /**
   * Marks a model path to be updated with the given value.
   *
   * @see {@link ModelPreloader.set}
   */
  this.set = function set(path, segment, value) {
    preloader.set(path, segment, value);
  };

  /**
   * Marks a model path to be resolved.
   *
   * @see {@link ModelPreloader.resolve}
   */
  this.resolve = function resolve(path, segment) {
    preloader.resolve(path, segment);
  };

  /**
   * Validate vehicle and throw error
   */
  this.validateVehicle = function validateVehicle(vehicle) {
    const preprod = vehicle && vehicle.pubStates && isPreProd(vehicle.pubStates);

    if (
      !vehicle ||
      !vehicle.make ||
      !vehicle.model ||
      !vehicle.year ||
      !vehicle.submodels ||
      !vehicle.pubStates ||
      (isSubpage && preprod)
    ) {
      const errorMsg =
        preprod && isSubpage
          ? `InvalidVehicleError - ${vehiclePath} is a pre-production vehicle`
          : `VehicleValidatorError - Could not find ${vehiclePath}`;
      const ex = new Error(errorMsg);
      ex.status = HTTP_NOT_FOUND;
      ex.redirectPageTo404 = true;
      throw ex;
    }
  };

  this.dispatch = function dispatch(modelState, vehicle) {
    const isPreProdVehicle = isPreProd(vehicle.pubStates);
    const isUsed = isUsedPubState(vehicle.pubStates);

    const vehicleForContext = buildContextVehicleFromMakeModelSubmodelYear(vehicle, { isUsed, isPreProdVehicle });
    store.dispatch(updateVehicle(vehicleForContext));
  };

  /**
   * Preloads the model data into the store, but will 404 if the requested vehicle is invalid.
   *
   * @see {@link ModelPreloader.load}
   */
  this.load = function load() {
    return preloader.load().then(modelState => {
      const vehicle = modelState.get(vehiclePath, VehicleModel);

      this.validateVehicle(vehicle);
      this.dispatch(modelState, vehicle);

      return modelState;
    });
  };
}
