import React, { PureComponent } from 'react';
import { AppraisalWidgetInfo } from 'client/data/models/inventory';
import PropTypes from 'prop-types';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { isEmpty, get } from 'lodash';
import { getPriceString } from 'site-modules/shared/utils/price-utils';
import { numberWithCommas } from 'site-modules/shared/utils/string';
import { EstimatedValuesData } from 'site-modules/shared/components/estimated-values/estimated-values-data';
import { DEALER_RETAIL_VALUE } from 'site-modules/shared/constants/appraisal/appraisal';
import './appraisal-section.scss';

export class AppraisalSection extends PureComponent {
  static propTypes = {
    urlContext: PropTypes.shape({}),
    appraisalWidgetInfo: AppraisalWidgetInfo,
    isMobile: PropTypes.bool,
  };

  static defaultProps = {
    urlContext: {},
    appraisalWidgetInfo: null,
    isMobile: false,
  };

  render() {
    const { urlContext, appraisalWidgetInfo, isMobile } = this.props;

    if (isEmpty(appraisalWidgetInfo)) return null;

    const location = get(urlContext, 'location', {});
    const year = get(appraisalWidgetInfo, 'vehicle.year');
    const make = get(appraisalWidgetInfo, 'vehicle.make');
    const model = get(appraisalWidgetInfo, 'vehicle.model');
    const averagePrice = get(appraisalWidgetInfo, 'pagePriceAverage');
    const estimatedAppraisalMileage = get(appraisalWidgetInfo, 'pageMileageAverage');
    const { city, stateCode, stateName } = location;

    const averagePriceFormatted = getPriceString(averagePrice);
    const averageMileage = numberWithCommas(Number(estimatedAppraisalMileage) || 0);
    const appraisalValue = getPriceString(get(appraisalWidgetInfo, 'tmvBands.AVERAGE.usedTradeIn'));
    const styleName = get(appraisalWidgetInfo, 'vehicle.style');
    const vehicleName = `${year} ${make} ${model} ${styleName}`.trim();

    return (
      <Container className="appraisal-section text-gray-darker mt-2">
        <h3 className="heading-3 mb-1_5 text-center">
          Used {year} {make} {model} in {city}, {stateCode} Values
        </h3>
        <Row className="justify-content-center">
          <Col xs={12} lg={8}>
            <div className="estimated-values-container rounded p-1 p-lg-1_5 pb-1_5">
              <div className="heading-3 mb-0_25">Estimated values</div>
              <div className="text-gray-darker mb-0_25">{vehicleName} with no options</div>
              <EstimatedValuesData
                appraisalWidgetInfo={appraisalWidgetInfo.tmvBands}
                styleName={styleName}
                defaultMobileValue={DEALER_RETAIL_VALUE}
                isMobile={isMobile}
                withCaption={false}
              />
            </div>
          </Col>
        </Row>
        <p className="size-16 mt-1_5 mb-0">
          The average list price of a used {year} {make} {model} in {city}, {stateName} is {averagePriceFormatted}. The
          average mileage on a used {make} {model} {year} for sale in {city}, {stateName} is {averageMileage}. Based on
          the average mileage of {averageMileage} for used {year} {make} {model} for sale in {city}, {stateCode} and
          using an average vehicle condition rating, which means the vehicle may have a few mechanical and/or cosmetic
          problems and may require a considerable amount of reconditioning, we appraise the average trade-in value of a
          used {make} {model} {year} in {city}, {stateName} to be {appraisalValue}.
        </p>
      </Container>
    );
  }
}
