import React from 'react';
import PropTypes from 'prop-types';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { PageModel } from 'client/data/models/page';
import { UsurpErrorReloadButton } from 'site-modules/inventory/components/usurp-error-reload/usurp-error-reload-button';

export function UsurpErrorReloadUI(props) {
  const { pageLoadingState } = props;
  const { isPreloaded, isPreloadSuccess } = pageLoadingState;

  return isPreloaded && !isPreloadSuccess ? (
    <UsurpErrorReloadButton>Something went wrong. Click to retry</UsurpErrorReloadButton>
  ) : null;
}

UsurpErrorReloadUI.propTypes = {
  pageLoadingState: PropTypes.shape({ isPreloadSuccess: PropTypes.bool, isPreloaded: PropTypes.bool }),
};

UsurpErrorReloadUI.defaultProps = {
  pageLoadingState: {},
};

const stateToPropsConfig = {
  pageLoadingState: bindToPath('loading', PageModel),
};

export const UsurpErrorReload = connectToModel(UsurpErrorReloadUI, stateToPropsConfig);
