import { buildFacetValueNiceName } from 'site-modules/shared/utils/inventory/build-facet-value-nice-name';
import { compact, flatten, get } from 'lodash';

/**
 * Generate the placeholder with active YMMT.
 * @param {object} activeFilters it is the active filters selected by user.
 * @returns {string}
 */
export function getYearMakeModelTrimPlaceholder(activeFilters) {
  if (activeFilters.length) {
    const makes = activeFilters.filter(({ type }) => type === 'make');
    const models = activeFilters.filter(({ type }) => type === 'model');
    const trims = activeFilters.filter(({ type }) => type === 'trim');
    const year = activeFilters.filter(({ type }) => type === 'year');

    if (!makes.length) {
      return '';
    }

    const selectedMakeModels = flatten(
      makes.map(make => {
        const makeModels = models.filter(({ group }) => group.includes(make.name));
        return makeModels.length ? makeModels.map(model => [make, model]) : [[make]];
      })
    );

    const selectedMakeModelsTrims = flatten(
      selectedMakeModels.map(makeModel => {
        const [make, model] = makeModel;
        const modelsTrims = model ? trims.filter(({ group }) => group.includes(`${make.name}|${model.name}`)) : [];
        return modelsTrims.length ? modelsTrims.map(trim => [make, model, trim]) : [makeModel];
      })
    );

    const partsOfPlaceholder = selectedMakeModelsTrims.map(([make, model, trim]) =>
      compact([make.name, get(model, 'name'), get(trim, 'name')]).join(' ')
    );

    if (year.length) {
      partsOfPlaceholder.unshift(buildFacetValueNiceName(year[0]).name);
    }

    return partsOfPlaceholder.join(' ');
  }

  return '';
}
