import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, isNil } from 'lodash';
import { AppraisalWidgetInfo } from 'client/data/models/inventory';
import {
  TRADE_IN_VALUE,
  DEALER_RETAIL_VALUE,
  PRIVATE_PARTY_VALUE,
} from 'site-modules/shared/constants/appraisal/appraisal';

import { ESTIMATED_VALUES_CONDITION } from './estimated-values-constants';
import { EstimatedValuesTypeSelection } from './estimated-values-type-selection';
import { EstimatedValuesTable } from './estimated-values-table';

export class EstimatedValuesData extends Component {
  static propTypes = {
    styleName: PropTypes.string,
    isMobile: PropTypes.bool,
    appraisalWidgetInfo: AppraisalWidgetInfo,
    defaultMobileValue: PropTypes.string,
    withCaption: PropTypes.bool,
  };

  static defaultProps = {
    appraisalWidgetInfo: null,
    styleName: '',
    isMobile: false,
    showHeaderCaption: true,
    defaultMobileValue: TRADE_IN_VALUE,
    withCaption: true,
  };

  /**
   * Get condition value entry object
   * @param {String} condition - condition string
   * @param {Object} appraisalValues - appraisal values
   */
  getConditionEntry = (condition, appraisalValues) => {
    if (!appraisalValues) return null;
    let usedTradeIn;
    let usedPrivateParty;
    let usedTmvRetail;
    const totalWithOptions = get(appraisalValues, 'Current.totalWithOptions');
    if (totalWithOptions) {
      ({ usedTradeIn, usedPrivateParty, usedTmvRetail } = totalWithOptions);
    } else {
      ({ usedTradeIn, usedPrivateParty, usedTmvRetail } = appraisalValues);
    }
    return {
      name: condition,
      [TRADE_IN_VALUE]: usedTradeIn,
      [PRIVATE_PARTY_VALUE]: usedPrivateParty,
      [DEALER_RETAIL_VALUE]: usedTmvRetail,
    };
  };

  /**
   * Gets converted conditions with price values.
   * @returns {object[]}
   */
  getConditionValues = () => {
    const { appraisalWidgetInfo } = this.props;
    return isEmpty(appraisalWidgetInfo)
      ? []
      : ESTIMATED_VALUES_CONDITION.map(condition =>
          this.getConditionEntry(condition, appraisalWidgetInfo[condition.toUpperCase()])
        ).filter(condition => !isNil(condition));
  };

  render() {
    const { isMobile, defaultMobileValue, styleName, withCaption } = this.props;
    const conditionValues = this.getConditionValues();

    return conditionValues.length ? (
      <Fragment>
        {isMobile ? (
          <EstimatedValuesTypeSelection
            conditionValues={conditionValues}
            defaultValue={defaultMobileValue}
            styleName={styleName}
            withCaption={withCaption}
          />
        ) : (
          <EstimatedValuesTable conditionValues={conditionValues} styleName={styleName} withCaption={withCaption} />
        )}
      </Fragment>
    ) : null;
  }
}
