import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Col from 'reactstrap/lib/Col';
import ListGroup from 'reactstrap/lib/ListGroup';
import ListGroupItem from 'reactstrap/lib/ListGroupItem';

import './rating-stars-chart.scss';

export function RatingStarsChart({ ratingsTotalCount, ratings, backGroundColor }) {
  return (
    <ListGroup className="rating-stars-chart">
      {ratings.map(({ value, number }) => {
        const percentageOfAllRatings = Math.round((value / ratingsTotalCount) * 100);
        return (
          <ListGroupItem key={number} className={classnames('medium border-0 p-0', backGroundColor)}>
            <Col xs={2} className="pl-0">
              <span className="text-gray-darker mr-0_5">
                {number} <span className="sr-only">star</span>
              </span>
              <span className="icon-star-full text-primary-darker" aria-hidden />
            </Col>
            <Col xs={8} className="chart-line bg-gray-lighter px-0">
              <div className="chart-line-inner bg-primary-darker" style={{ width: `${percentageOfAllRatings}%` }} />
            </Col>
            <Col xs={2} className="pr-0 text-right">
              ({percentageOfAllRatings}%)
            </Col>
          </ListGroupItem>
        );
      })}
    </ListGroup>
  );
}

RatingStarsChart.propTypes = {
  ratingsTotalCount: PropTypes.number.isRequired,
  ratings: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      number: PropTypes.number,
    })
  ).isRequired,
  backGroundColor: PropTypes.string,
};

RatingStarsChart.defaultProps = {
  backGroundColor: null,
};
