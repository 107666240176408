import get from 'lodash/get';
import {
  isLeaseSRP,
  isUsedCheapCarsSRP,
  isMakeDealershipSRP,
  isMakeDealershipCityStateSRP,
} from 'site-modules/shared/utils/inventory/srp-type-checkers';

import { getUrlPattern } from 'site-modules/shared/utils/inventory/srp-utils';
import { USED_SRP } from 'site-modules/shared/constants/inventory/srp-page-types';

export const getShowPopularVehicles = urlContext => {
  const urlPath = getUrlPattern(urlContext);
  const isFlatUsedSrp = get(urlContext, 'attributes.page') === USED_SRP;
  const isCheapCarsSrp = isUsedCheapCarsSRP(urlPath);

  return isFlatUsedSrp && !isCheapCarsSrp;
};

export const getHasLinkWidget = urlContext => {
  const urlPath = getUrlPattern(urlContext);
  const isSrpOnMakeType = get(urlContext, 'isSrpOnMakeType');
  const isSrpOnUsedCore = get(urlContext, 'isSrpOnUsedCore');
  const isLeaseSrp = isLeaseSRP(urlContext);

  return (
    urlPath && // Only SRPs with flat URL have link widgets
    !isSrpOnMakeType &&
    (isLeaseSrp ||
      urlPath.includes('used-') ||
      urlPath.includes('new-') ||
      isSrpOnUsedCore ||
      isMakeDealershipSRP(urlPath) ||
      isMakeDealershipCityStateSRP(urlPath))
  );
};
