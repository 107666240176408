import { get } from 'lodash';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { isNew } from './is-new';

/**
 * Return the vehicle from the first new vehicle with oemModelCode, otherwise return the vehicle from the first new vehicle
 *
 * @param {Object} inventories Inventory data.
 * @returns {object}
 */
export function getFirstNewVehicle(inventories) {
  const newInventories =
    inventories &&
    (inventories.find(({ type, vehicleInfo }) => isNew(type) && get(vehicleInfo, 'styleInfo.oemModelCode', '')) ||
      inventories.find(({ type }) => isNew(type)));
  const styleInfo = get(newInventories, 'vehicleInfo.styleInfo');
  return (
    styleInfo && {
      make: makeNiceName(styleInfo.make),
      model: makeNiceName(styleInfo.model),
    }
  );
}
