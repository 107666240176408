import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { LinkWidgetTable } from 'site-modules/shared/components/link-widget-table/link-widget-table';

import './great-deals-link-widget-table.scss';

const MOBILE_HEADERS = {
  'Starting Price': 'From',
  'Total Available': 'Available',
};
const TYPES = {
  DEALS: 'Deals',
};
const MOBILE_TEXT_TYPE_REPLACEMENTS = [TYPES.DEALS];
const REPLACEMENTS_REGEX = {
  [TYPES.DEALS]: {
    MATCH: '/Deal|s/g',
    TO: '',
  },
};

/**
 * Transforms data to mobile view.
 * @param {object} data
 */
function transformDataToMobile(data) {
  const { headers, records } = data;
  const mobileHeaders = headers.map(header => MOBILE_HEADERS[header] || header);
  const mobileRecords = records.map(({ items }) => ({
    items: items.map(item => {
      const { type, anchorText } = item;
      let text;

      if (MOBILE_TEXT_TYPE_REPLACEMENTS.includes(type)) {
        const { MATCH, TO } = REPLACEMENTS_REGEX[type];
        text = anchorText.replace(MATCH, TO).trim();
      }

      return {
        ...item,
        anchorText: text || anchorText,
      };
    }),
  }));
  return {
    ...data,
    headers: mobileHeaders,
    records: mobileRecords,
  };
}

export const GreatDealsLinkWidgetTable = memo(({ data, isMobile, className }) => {
  const mobileData = isMobile && transformDataToMobile(data);
  return (
    <LinkWidgetTable data={mobileData || data} className={className} tableClassName="great-deals-link-widget-table" />
  );
});

GreatDealsLinkWidgetTable.displayName = 'GreatDealsLinkWidgetTable';

GreatDealsLinkWidgetTable.propTypes = {
  data: PropTypes.shape({}).isRequired,
  isMobile: PropTypes.bool,
  className: PropTypes.string,
};

GreatDealsLinkWidgetTable.defaultProps = {
  isMobile: false,
  className: '',
};
