import React, { Fragment, memo, useMemo } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { get } from 'lodash';
import classNames from 'classnames';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';
import { UsurpStickyHeaderSemanticSearch } from 'site-modules/inventory/components/usurp-sticky-header-semantic-search/usurp-sticky-header-semantic-search';
import { SKIP_TO_LINKS_CONFIG } from 'site-modules/shared/constants/inventory/routes-constants';
import { ScrollLink } from 'site-modules/shared/components/scroll-link/scroll-link';
import { GLOBAL_HEADER_OFFSET } from 'site-modules/shared/constants/sub-navigation';
import { ZipCodeReminder } from 'site-modules/inventory/components/srp/zip-code-reminder/zip-code-reminder';
import { IcoEdmundsThemeTrigger } from 'site-modules/shared/components/dealer-ico-widget/ico-edmunds-theme-trigger/ico-edmunds-theme-trigger';
import { SrpTopMessage } from 'site-modules/inventory/components/srp-top-message/srp-top-message';
import { UsurpCardList } from 'site-modules/shared/components/inventory/usurp-card-list/usurp-card-list';
import { InventoriesNotFoundWithLinks } from 'site-modules/shared/components/inventory/inventories-not-found-with-links/inventories-not-found-with-links';
import { SrpPagination } from 'site-modules/shared/components/inventory/srp-pagination/srp-pagination';
import { Breadcrumbs } from 'site-modules/shared/components/breadcrumbs/breadcrumbs';
import { InventoryAdTargetingPrefetch } from 'site-modules/shared/components/native-ad/inventory-ad-targeting/inventory-ad-targeting-prefetch';
import { MrectNative } from 'site-modules/shared/components/native-ad/mrect/mrect-native';
import { NativeAd } from 'site-modules/shared/components/ad-unit/native-ad';
import { ResearchPageLink } from 'site-modules/inventory/components/research-page-link/research-page-link';
import { ConsumerReviewsWidget } from 'site-modules/inventory/components/consumer-reviews-widget/consumer-reviews-widget';
import { isCpo } from 'site-modules/shared/utils/inventory-utils/is-cpo';
import { CertifiedPreOwned } from 'site-modules/shared/components/certified-pre-owned/certified-pre-owned';
import { GreatDealsLinkWidgetTable } from 'site-modules/inventory/components/great-deals-link-widget-table/great-deals-link-widget-table';
import { LeaseDealsLinkWidgetTable } from 'site-modules/inventory/components/lease-deals-link-widget-table/lease-deals-link-widget-table';
import {
  isCheapLeaseSRP,
  isLeaseSRP,
  isNewFlatUrlSRP,
  isUsedYMMCityStateSRP,
} from 'site-modules/shared/utils/inventory/srp-type-checkers';
import { InventoryDataTable } from 'site-modules/inventory/components/inventory-data-table/inventory-data-table';
import { ErrorBoundary } from 'site-modules/shared/components/error-boundary/error-boundary';
import { FaqModule } from 'site-modules/inventory/components/faq-module/faq-module';
import { AppraisalSection } from 'site-modules/inventory/components/appraisal-section/appraisal-section';
import { UsurpFiltersPanel } from 'site-modules/shared/components/inventory/usurp-filters-panel/usurp-filters-panel';
import { UsurpFilters } from 'site-modules/shared/components/inventory/usurp-filters/usurp-filters';
import { ELECTRICITY_RANGE, MODEL, SORT_BY } from 'site-modules/shared/constants/allowed-inventory-request-params';
import { PricingCarouselFooter } from 'site-modules/shared/components/core-page/pricing-carousel/pricing-carousel-footer';
import { StaticLeaseLinkWidget } from 'site-modules/shared/components/static-lease-link-widget/static-lease-link-widget';
import { PageLinksWidget } from 'site-modules/shared/components/page-links-widget/page-links-widget';
import { SrpDisclaimer } from 'site-modules/inventory/components/srp-disclaimer/srp-disclaimer';
import { SrpCardsTopPanel } from 'site-modules/shared/components/inventory/srp-cards-top-panel/srp-cards-top-panel';
import { expandedRadiusDueToLowResultsSort } from 'site-modules/shared/constants/sort-types';
import { UsurpInventoryCard } from 'site-modules/shared/components/inventory/usurp-inventory-card/usurp-inventory-card';
import { EvEBuyingExperienceButtonGroup } from 'site-modules/shared/components/eve-buying-experience-button-group/eve-buying-experience-button-group';
import { ANY_BTN, IN_STORE_BTN } from 'site-modules/shared/constants/inventory/buying-experience';
import { PUB_STATES_LOWERCASE } from 'client/constants/pub-states';
import { isUnknownZip } from 'site-modules/shared/utils/location';
import { getFirstNewVehicle } from 'site-modules/shared/utils/inventory-utils/get-first-new-vehicle';
import { isNoInventoryPorscheMessage } from 'site-modules/shared/components/inventory/utils/no-inventory-message';
import {
  getLinkWidgetTitle,
  getPageName,
  getPagePublicationState,
  getUrlPattern,
} from 'site-modules/shared/utils/inventory/srp-utils';
import { buildCoreBreadcrumbs } from 'site-modules/shared/utils/core-breadcrumbs-builder';
import { getMakeTypeBreadcrumbs } from 'site-modules/shared/components/breadcrumbs/srp-helper';
import {
  generateSEOBreadcrumbs,
  SearchFilterSeoObjectEntities,
} from 'site-modules/shared/utils/inventory/srp-seo-utils';
import { getHasLinkWidget } from 'site-modules/inventory/utils/link-widget';
import PropTypes from 'prop-types';
import { InventoryEntities } from 'client/data/models/inventory';
import { VisitorEntities } from 'client/data/models/visitor';
import { VehicleEntities } from 'client/data/models/vehicle-v2';
import { ZipLongDistanceConfirmProvider } from 'site-modules/shared/components/lead-form/zip-long-distance-confirm/zip-long-distance-confirm-provider';
import { SrpCoreContent } from 'site-modules/inventory/components/srp-core-content/srp-core-content';
import { RenderWhenViewable } from 'site-modules/shared/components/render-when-viewable/render-when-viewable';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';
import { MinHeightWrapper } from 'site-modules/shared/components/min-height-wrapper/min-height-wrapper';
import { SponsoredCars } from 'site-modules/shared/components/core-page/sponsored-cars/sponsored-cars';

export const UsurpMainContent = memo(props => {
  const {
    disableInventoryMLPhotoSort,
    isNational,
    inventoryData,
    visitorLocation,
    paymentType,
    selectedPaymentType,
    radius,
    isDealerSrp,
    isMobile,
    facets,
    selectedFacets,
    seoData,
    filteringByFacets,
    onOpenFiltersDrawer,
    onSuggestedFacetUpdate,
    onUpdateFilter,
    zipCode,
    isLoading,
    onUpdateZipCode,
    isSrpOnUsedCore,
    pathName,
    userCapOnePrequalApproved,
    urlContext,
    makeModelSubmodelYear,
    isSrpOnMakeType,
    onRemoveCapOnePrequalParam,
    onMultiMMChange,
    onPageChange,
    year,
    submodel,
    isEnableFirstSentence,
    withCoreReviewSection,
    isShop2901Enabled,
    isVinPreview,
    disableDrivewayNewCarLeadForms,
  } = props;

  const isExpandedRadiusDueToLowResults = !!get(inventoryData, 'attributes.expandedRadiusDueToLowResults', false);
  const attrRadius = get(inventoryData, 'attributes.radius');
  const isExpandedRadiusDueToLowResultsSort = isExpandedRadiusDueToLowResults && attrRadius === 6000;
  const { totalPages, pageSize, pageNumber, totalNumber: inventoryCount, maxAllowedPageNumber } = get(
    inventoryData,
    'inventories',
    {}
  );
  const attributes = get(inventoryData, 'attributes', {});
  const inventoryType = get(attributes, 'inventoryType', '');
  const isLowInventoryLeadForm = !!get(attributes, 'lowInventoryLeadForm');
  const suggestedFacets = get(inventoryData, 'suggestedFacets', []);
  const hasBuyOnlineInventories = get(attributes, 'buyOnlineCount', 0) > 0;
  const isLocalDeliveryType = get(selectedFacets, 'deliveryType[0]') === 'local';
  const selectedBtn = isLocalDeliveryType ? IN_STORE_BTN : ANY_BTN;
  const selectedCarTypes = get(selectedFacets, 'inventoryType', []);
  const pagePublicationState = getPagePublicationState(selectedCarTypes);
  const isUsed = pagePublicationState === PUB_STATES_LOWERCASE.USED;
  const isInternational = isUnknownZip(visitorLocation);
  const dealerName = get(attributes, 'dealerInfo.name');
  const dealerId = get(attributes, 'dealerInfo.rooftopId');
  const isNewPageState = [PUB_STATES_LOWERCASE.NEW, PUB_STATES_LOWERCASE.NEW_USED].includes(pagePublicationState);
  const inventory = get(inventoryData, 'inventories.results', []);
  const firstNewVehicle = isNewPageState && getFirstNewVehicle(inventory);
  const make = get(attributes, 'vehicle.make.name', '');
  const makeSlug = get(attributes, 'vehicle.make.niceId', '');
  const modelSlug = get(attributes, 'vehicle.model.niceId', '');
  const isNoInventoryPorsche = isNoInventoryPorscheMessage({
    make,
    isNewPageState,
    facets: get(inventoryData, 'facets', []),
  });
  const displayLowInvModule = inventoryCount <= 5 && !isLoading;
  const urlVehicle = get(urlContext, 'vehicle');
  const vehicle = urlVehicle || makeModelSubmodelYear;
  const consumerReviews = get(inventoryData, 'seoInfo.consumerReviews');
  const { latestYear } = seoData;
  const greatDealsWidgetData = get(inventoryData, 'seoInfo.greatDealsWidget');
  const leaseDealsWidgetData = get(inventoryData, 'seoInfo.leaseDealsWidget');
  const urlPath = getUrlPattern(urlContext);
  const pageName = getPageName(urlPath);
  const faqApiData = get(inventoryData, 'seoInfo.faqWidget');
  const hasFaqApiData = !!faqApiData && faqApiData.items.length > 0;
  const usedYearAppraisalWidget = isUsedYMMCityStateSRP(urlPath)
    ? get(inventoryData, 'seoInfo.usedYearAppraisalWidget')
    : {};
  const isLease = isLeaseSRP(urlContext);
  const isCheapLease = isCheapLeaseSRP(urlPath);
  const linkWidgetTitle = getLinkWidgetTitle({ isLease, urlContext, makeModelSubmodelYear });
  const hasLinkWidget = getHasLinkWidget(urlContext);
  const hasStaticLeaseLinkWidget = isCheapLease;
  const hasAnyLinkWidget = hasLinkWidget || hasStaticLeaseLinkWidget;
  const urlVehicleType = get(urlContext, 'attributes.inventory.type');
  const urlVehicleWithType = useMemo(() => ({ ...urlVehicle, type: urlVehicleType }), [urlVehicle, urlVehicleType]);

  const cardListProps = {
    visitorLocation,
    paymentType,
    selectedPaymentType,
    radius,
    isNational,
    isDealerSrp,
    isMobile,
    disableInventoryMLPhotoSort,
    isExpandedRadius: isExpandedRadiusDueToLowResults,
    cardComponent: UsurpInventoryCard,
    grayIcons: true,
    isEnhancedName: true,
    showNationalDelivery: isNational,
    isSrp: true,
    isShop2901Enabled,
    cardKeyPrefix: inventoryData?.hash,
    disableDrivewayNewCarLeadForms,
  };

  const firstSentence = get(inventoryData, 'seoInfo.firstSentence');
  const vehicleParams = useMemo(() => ({ year, make: makeSlug, model: modelSlug }), [makeSlug, modelSlug, year]);
  const stickyHeaderProps = {
    seoData,
    attributes,
    visitorLocation,
    facets,
    selectedFacets,
    suggestedFacets,
    filteringByFacets,
    onFiltersBtnClick: onOpenFiltersDrawer,
    onUpdate: isMobile ? onSuggestedFacetUpdate : onUpdateFilter,
    isNational,
    isDealerSrp,
    isMobile,
    firstSentence,
    isEnableFirstSentence,
    vehicleParams,
  };

  const facetsProps = {
    paymentType: selectedPaymentType,
    facets,
    filteringByFacets,
    onUpdate: onUpdateFilter,
    zipCode,
    onZipCodeUpdate: onUpdateZipCode,
    isNational,
    isDealerSrp,
    selectedFacets,
    setIsMultiMM: onMultiMMChange,
    searchResultsFound: inventoryCount,
    pagePublicationState,
    hasBuyOnlineInventories,
  };

  let breadcrumbsData;
  if (makeModelSubmodelYear) {
    breadcrumbsData = buildCoreBreadcrumbs({
      makeModelSubmodelYear,
      params: { year, submodel },
      includeLastLink: true,
    });
  } else if (isSrpOnMakeType) {
    breadcrumbsData = getMakeTypeBreadcrumbs(seoData);
  } else {
    breadcrumbsData = generateSEOBreadcrumbs({
      originalUrl: pathName,
      seoData,
      includeLastLink: true,
    });
  }

  const srpCardsTopPanel = (
    <SrpCardsTopPanel
      onUpdate={onUpdateFilter}
      inventoryCount={inventoryCount}
      isMobile={isMobile}
      withRange={!!facets.find(({ type }) => type === ELECTRICITY_RANGE)}
      selectedFacets={
        !get(selectedFacets, 'sortBy') && isExpandedRadiusDueToLowResultsSort
          ? { ...selectedFacets, [SORT_BY]: [expandedRadiusDueToLowResultsSort] }
          : selectedFacets
      }
    />
  );

  return (
    <Container className="pb-3">
      <FeatureFlag name="disable-srp-llm">
        {disableLlm => (
          <UsurpStickyHeaderSemanticSearch
            {...stickyHeaderProps}
            disableLlm={disableLlm}
            withCoreReviewSection={withCoreReviewSection}
            isVinPreview={isVinPreview}
          />
        )}
      </FeatureFlag>
      <Row className="flex-row-reverse">
        <Col
          xs={12}
          lg={9}
          role="region"
          aria-label="Listings"
          tabIndex={-1}
          id={SKIP_TO_LINKS_CONFIG.RESULTS_CONTAINER_ID}
        >
          {!isMobile && (
            <ScrollLink
              to={SKIP_TO_LINKS_CONFIG.FILTERS_CONTAINER_ID}
              className="sr-only sr-only-focusable d-none d-lg-block"
              scrollConfig={{ offset: GLOBAL_HEADER_OFFSET }}
            >
              {SKIP_TO_LINKS_CONFIG.FILTERS_CONTAINER_LABEL}
            </ScrollLink>
          )}

          {withCoreReviewSection && !isMobile && <SrpCoreContent vehicleParams={vehicleParams} isMobile={false} />}

          {!isMobile && srpCardsTopPanel}

          {isNational && (
            <ZipCodeReminder
              onZipCodeUpdate={onUpdateZipCode}
              isNational={isNational}
              isMobile={isMobile}
              zipCode={zipCode}
              isLoading={isLoading}
            />
          )}
          {isMobile && isUsed && hasBuyOnlineInventories && (
            <EvEBuyingExperienceButtonGroup
              selectedFacets={selectedFacets}
              isMobile={isMobile}
              selectedBtn={selectedBtn}
              withCoreReviewSection={withCoreReviewSection}
            />
          )}
          {dealerId && (
            <IcoEdmundsThemeTrigger dealerName={dealerName} dealerId={String(dealerId)} isMobile={isMobile} />
          )}
          <SrpTopMessage
            selectedFacets={selectedFacets}
            inventoryData={inventoryData}
            pathName={pathName}
            pageName={pageName}
            isNoInventoryPorsche={isNoInventoryPorsche}
            isLocalDeliveryType={isLocalDeliveryType}
            isExpandedRadiusDueToLowResults={isExpandedRadiusDueToLowResults}
            isMobile={isMobile}
            userCapOnePrequalApproved={userCapOnePrequalApproved}
            onUpdate={onUpdateFilter}
            onRemoveCapOnePrequalParam={onRemoveCapOnePrequalParam}
          />

          {isMobile && srpCardsTopPanel}

          <ZipLongDistanceConfirmProvider>
            <UsurpCardList inventory={inventory} {...cardListProps} />
          </ZipLongDistanceConfirmProvider>

          {displayLowInvModule && (
            <InventoriesNotFoundWithLinks
              attributes={attributes}
              selectedFacets={selectedFacets}
              inventoriesCount={inventoryCount}
              onUpdate={onUpdateFilter}
              onZipCodeUpdate={onUpdateZipCode}
              isNoInventory={isNoInventoryPorsche}
              withLeadForm
            />
          )}
          <SrpPagination
            pageNumber={pageNumber}
            pagesCount={totalPages}
            maxAllowedPageNumber={maxAllowedPageNumber}
            pageSize={pageSize}
            pageResultsCount={inventory.length}
            inventoryCount={inventoryCount}
            selectedFacets={selectedFacets}
            urlContext={urlContext}
            pathName={pathName}
            onPageLinkClick={onPageChange}
            isMobile={isMobile}
          />
          <Experiment name="ads-10219" showDefault>
            <Recipe name="ctrl" isDefault />
            <Recipe name="chal">
              <MinHeightWrapper componentType="SPONSORED_CARS" className="mt-1 pt-1">
                <SponsoredCars showLargeArrows showBottomDividingLine={false} />
              </MinHeightWrapper>
            </Recipe>
          </Experiment>
          <Breadcrumbs data={breadcrumbsData} className="p-0 my-1" />
          {isMobile && (
            <Fragment>
              <div className="mt-1 d-flex">
                <InventoryAdTargetingPrefetch>
                  <MrectNative
                    wrapperClass="mrect mx-auto"
                    mobileOnly
                    refreshable={false}
                    renderWhenViewable
                    verticalOffset="100%"
                    useMinHeight
                  />
                </InventoryAdTargetingPrefetch>
              </div>
              <div className="my-1">
                {selectedFacets.make && selectedFacets.model && (
                  <InventoryAdTargetingPrefetch>
                    <RenderWhenViewable verticalOffset="-180px">
                      <NativeAd
                        wrapperClass="mx-auto filmstrip"
                        nativeStyle="filmstrip"
                        position={'1'}
                        refreshable
                        noRefresh={['scroll', 'engagement']}
                        mobileOnly
                        useMinHeight
                      />
                    </RenderWhenViewable>
                  </InventoryAdTargetingPrefetch>
                )}
              </div>
            </Fragment>
          )}
          {!withCoreReviewSection && (
            <ResearchPageLink vehicle={vehicle} facets={filteringByFacets} inventory={inventory} />
          )}
          {!!consumerReviews && !!vehicle && !withCoreReviewSection && (
            <ConsumerReviewsWidget
              data={consumerReviews}
              vehicle={vehicle}
              latestYear={latestYear}
              isMobile={isMobile}
            />
          )}
          {Boolean(isCpo(inventoryType) && make) && <CertifiedPreOwned make={make} showMainHeader className="mt-2" />}
          {Boolean(greatDealsWidgetData) && (
            <GreatDealsLinkWidgetTable data={greatDealsWidgetData} isMobile={isMobile} className="mt-2" />
          )}
          {Boolean(leaseDealsWidgetData) && (
            <LeaseDealsLinkWidgetTable make={make} data={leaseDealsWidgetData} isMobile={isMobile} className="mt-2" />
          )}
          {Boolean(isNewFlatUrlSRP(urlPath) && make) && <InventoryDataTable inventory={inventory} className="mt-2" />}
          {hasFaqApiData && (
            <ErrorBoundary>
              <FaqModule className="mt-2" faqApiData={faqApiData} />
            </ErrorBoundary>
          )}
          {isUsedYMMCityStateSRP(urlPath) && (
            <AppraisalSection
              urlContext={urlContext}
              appraisalWidgetInfo={usedYearAppraisalWidget}
              isMobile={isMobile}
            />
          )}
        </Col>
        {!isMobile && (
          <Col lg={3} className="d-none d-lg-block filters-column">
            <ErrorBoundary>
              <UsurpFiltersPanel>
                <UsurpFilters {...facetsProps} />
              </UsurpFiltersPanel>
            </ErrorBoundary>
            <div className="adsol-mrect">
              <div className="first-mrect-wrapper hidden-lg-down">
                <InventoryAdTargetingPrefetch>
                  <MrectNative
                    refreshable
                    noRefresh={['scroll', 'engagement']}
                    wiredBreakpoints={{
                      xl: true,
                    }}
                    position={'1'}
                    renderWhenViewable
                    verticalOffset="100%"
                  />
                </InventoryAdTargetingPrefetch>
              </div>
            </div>
            {selectedFacets.make && selectedFacets.model && (
              <InventoryAdTargetingPrefetch>
                <RenderWhenViewable verticalOffset="-170px">
                  <NativeAd
                    wrapperClass="my-2_5 filmstrip"
                    nativeStyle="filmstrip"
                    position={'1'}
                    refreshable
                    noRefresh={['scroll', 'engagement']}
                    wiredOnly
                  />
                </RenderWhenViewable>
              </InventoryAdTargetingPrefetch>
            )}
            <div className="adsol-mrect">
              <div className="second-mrect-wrapper hidden-lg-down">
                <InventoryAdTargetingPrefetch>
                  <MrectNative
                    position={'2'}
                    refreshable={false}
                    wiredBreakpoints={{
                      xl: true,
                    }}
                    renderWhenViewable
                    verticalOffset="100%"
                  />
                </InventoryAdTargetingPrefetch>
              </div>
            </div>
          </Col>
        )}
      </Row>
      {firstNewVehicle && !!get(selectedFacets, MODEL) && !isNational && !isLowInventoryLeadForm && (
        <div className={classNames('usurp-pricing-carousel-wrapper', { international: isInternational })}>
          <hr className="mt-1_5 mb-2" />
          <PricingCarouselFooter
            params={firstNewVehicle}
            srpParams={{
              ...firstNewVehicle,
              inventorytype: 'new',
            }}
            leadFormOptions={{
              thankYouLayoutOptions: { hideSrpLink: true },
            }}
          />
        </div>
      )}
      {hasAnyLinkWidget && <hr className="mt-1_5 mb-2" />}
      {hasStaticLeaseLinkWidget && (
        <StaticLeaseLinkWidget pageName={pageName} columns={3} titleClassName="heading-3 mb-1_5" />
      )}
      {hasLinkWidget && (
        <PageLinksWidget
          divideSections={false}
          title={linkWidgetTitle}
          pageName={pageName}
          vehicle={isSrpOnUsedCore ? makeModelSubmodelYear : urlVehicleWithType}
          className="mt-2 pb-3"
          inventory={inventory}
        />
      )}
      <SrpDisclaimer inventory={inventory} />
    </Container>
  );
});

UsurpMainContent.displayName = 'UsurpMainContent';

UsurpMainContent.propTypes = {
  inventoryData: PropTypes.shape({
    inventories: PropTypes.shape({
      results: InventoryEntities.InventoriesVin,
      totalNumber: PropTypes.number,
      totalPages: PropTypes.number,
    }),
    facets: InventoryEntities.Facets,
    seoInfo: PropTypes.shape({}),
  }).isRequired,
  radius: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  selectedFacets: PropTypes.shape({}).isRequired,
  urlContext: PropTypes.shape({}),
  zipCode: PropTypes.string.isRequired,
  location: PropTypes.shape({}),
  visitorLocation: VisitorEntities.Location,
  isSrpOnMakeType: PropTypes.bool,
  makeModelSubmodelYear: VehicleEntities.MakeModelSubmodelYear,
  isSrpOnUsedCore: PropTypes.bool,
  disableInventoryMLPhotoSort: PropTypes.bool,
  isNational: PropTypes.bool,
  paymentType: PropTypes.string,
  selectedPaymentType: PropTypes.string,
  isDealerSrp: PropTypes.bool,
  facets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  seoData: SearchFilterSeoObjectEntities.isRequired,
  filteringByFacets: PropTypes.arrayOf(PropTypes.shape({})),
  onOpenFiltersDrawer: PropTypes.func.isRequired,
  onSuggestedFacetUpdate: PropTypes.func.isRequired,
  onUpdateFilter: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onUpdateZipCode: PropTypes.func.isRequired,
  pathName: PropTypes.string,
  userCapOnePrequalApproved: PropTypes.bool,
  onRemoveCapOnePrequalParam: PropTypes.func.isRequired,
  onMultiMMChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  year: PropTypes.string,
  submodel: PropTypes.string,
  isEnableFirstSentence: PropTypes.bool,
  withCoreReviewSection: PropTypes.bool,
  isShop2901Enabled: PropTypes.bool,
  isVinPreview: PropTypes.bool,
  disableDrivewayNewCarLeadForms: PropTypes.bool,
};

UsurpMainContent.defaultProps = {
  urlContext: {},
  location: {},
  visitorLocation: {},
  isSrpOnMakeType: false,
  makeModelSubmodelYear: null,
  isSrpOnUsedCore: false,
  disableInventoryMLPhotoSort: false,
  isNational: false,
  paymentType: '',
  selectedPaymentType: '',
  isDealerSrp: false,
  filteringByFacets: [],
  isLoading: false,
  pathName: '',
  userCapOnePrequalApproved: false,
  year: undefined,
  submodel: undefined,
  isEnableFirstSentence: false,
  withCoreReviewSection: false,
  isShop2901Enabled: false,
  isVinPreview: false,
  disableDrivewayNewCarLeadForms: false,
};
