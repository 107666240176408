import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import { compact, get, noop } from 'lodash';
import { srpLinkBuilder } from 'site-modules/shared/utils/srp-link-constructor';
import { getPagePublicationState } from 'site-modules/shared/utils/inventory/srp-utils';
import {
  getNoInventoryDealerlessMessageType,
  DEALERLESS_OEM_MSG_TYPE,
} from 'site-modules/shared/components/inventory/utils/no-inventory-message';
import { PUB_STATES_LOWERCASE } from 'client/constants/pub-states';
import { Link } from 'site-modules/shared/components/link/link';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';
import { TopMessageBox } from 'site-modules/shared/components/inventory/top-message-box/top-message-box';
import { CapOnePrequalApprovedMsg } from 'client/site-modules/shared/components/inventory/cap-one-prequal-approved-message/cap-one-prequal-approved-message';
import { SrpTopIncentives } from 'site-modules/shared/components/inventory/incentives-wizard/srp-top-incentives/srp-top-incentives';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';

import { SrpNarrativeText } from './srp-narrative-text/srp-narrative-text';

export const SrpTopMessage = memo(
  ({
    selectedFacets,
    inventoryData,
    pathName,
    pageName,
    isNoInventoryPorsche,
    isLocalDeliveryType,
    isExpandedRadiusDueToLowResults,
    isMobile,
    onUpdate,
    userCapOnePrequalApproved,
    onRemoveCapOnePrequalParam,
  }) => {
    const attributes = get(inventoryData, 'attributes', {});
    const make = get(attributes, 'vehicle.make.name', '');
    const model = get(attributes, 'vehicle.model.name', '');
    const makeSlug = get(attributes, 'vehicle.make.niceId', '');
    const attrRadius = get(attributes, 'radius');
    const isLowInventoryLeadForm = !!get(attributes, 'lowInventoryLeadForm');
    const { pageNumber } = get(inventoryData, 'inventories', {});
    const selectedCarTypes = get(selectedFacets, 'inventoryType', []);

    const pagePublicationState = getPagePublicationState(selectedCarTypes);
    const isNew = pagePublicationState === PUB_STATES_LOWERCASE.NEW;

    const dealerlessMessageType = getNoInventoryDealerlessMessageType({
      makeSlug,
      selectedCarTypes,
    });

    const showExpandedRadiusMsg = isExpandedRadiusDueToLowResults && attrRadius === 6000 && pageNumber === 1;

    const narrativeApiData = get(inventoryData, 'seoInfo.narrativeWidget');
    const hasNarrativeApiData = !!narrativeApiData && narrativeApiData.items.length > 0;

    const isUsed = pagePublicationState === PUB_STATES_LOWERCASE.USED;

    const incentives = get(inventoryData, 'seoInfo.incentives.incentiveAggregations.byGrantorAndSubtype', []).map(
      ({ topIncentiveByRebate }) => topIncentiveByRebate
    );
    const totalRebates = incentives?.reduce((total, { rebateAmount = 0 }) => total + rebateAmount, 0);
    const isIncentivesWizardActive = !!make && !!model;
    const engineTypes = get(inventoryData, 'seoInfo.incentives.engineTypes', []);
    const latestYear = Math.max(
      ...get(inventoryData, 'inventories.results', []).map(result => get(result, 'vehicleInfo.styleInfo.year', 0))
    );
    const bodyType = get(inventoryData, 'inventories.results', []).find(
      result => get(result, 'vehicleInfo.styleInfo.bodyType', '') !== ''
    )?.vehicleInfo.styleInfo.bodyType;

    switch (true) {
      case userCapOnePrequalApproved: {
        return (
          <CapOnePrequalApprovedMsg
            isMobile={isMobile}
            onUpdate={onUpdate}
            onRemoveCapOnePrequalParam={onRemoveCapOnePrequalParam}
          />
        );
      }
      case !!dealerlessMessageType: {
        return (
          <TopMessageBox creativeId="search_banner_message">
            New {make} vehicles must be ordered directly from the manufacturer.{' '}
            {dealerlessMessageType === DEALERLESS_OEM_MSG_TYPE.NEW_ONLY && (
              <Link
                to={srpLinkBuilder({ ...selectedFacets, inventorytype: 'used,cpo' })}
                className="text-underline"
                data-tracking-id="inventory_view_filter_search_result"
                data-tracking-value="search-used-dealerless"
              >
                Click here to shop used {compact([make, model]).join(' ')} listings.
              </Link>
            )}
          </TopMessageBox>
        );
      }
      case isNoInventoryPorsche: {
        return (
          <TopMessageBox creativeId="search_banner_message">
            As of February 1st, 2023, Porsche no longer allows new inventory listings on car shopping websites.
            {isNew && (
              <Fragment>
                {isLowInventoryLeadForm && ' Connect with a local dealer using the form below or'}{' '}
                <Link
                  to={srpLinkBuilder({ ...selectedFacets, inventorytype: 'used,cpo' })}
                  className="text-underline"
                  data-tracking-id="inventory_view_filter_search_result"
                  data-tracking-ignore-value
                >
                  {isLowInventoryLeadForm ? 'click' : 'Click'} here to shop similar used car listings.
                </Link>
              </Fragment>
            )}
          </TopMessageBox>
        );
      }
      case showExpandedRadiusMsg: {
        return (
          <TopMessageBox creativeId="edm-entry-radius-expanion-message" iconClassName="icon-alarm">
            Search radius has been expanded in order to return matching results.
          </TopMessageBox>
        );
      }
      case hasNarrativeApiData: {
        return (
          <SrpNarrativeText
            narrativeApiData={narrativeApiData}
            pathname={pathName}
            pageName={pageName}
            className="mb-1_5 mb-md-1 text-cool-gray-30"
          />
        );
      }
      case isUsed && isLocalDeliveryType: {
        return (
          <TopMessageBox iconClassName="icon-office">
            Shopping local results available for in-store purchase
          </TopMessageBox>
        );
      }
      case !!incentives?.length && totalRebates && isIncentivesWizardActive: {
        return (
          <FeatureFlag name="disable-ev-incentives-wizard">
            {disableWizard =>
              !disableWizard && (
                <>
                  {pagePublicationState === PUB_STATES_LOWERCASE.NEW && (
                    <SrpTopIncentives
                      incentives={incentives}
                      isMobile={isMobile}
                      attributes={get(inventoryData, 'attributes')}
                      engineTypes={engineTypes}
                      defaultYear={latestYear}
                      bodyType={bodyType}
                      pagePublicationState={pagePublicationState}
                    />
                  )}
                  {pagePublicationState === PUB_STATES_LOWERCASE.USED && (
                    <Experiment name="shop-2900-used-ev-wiz" showDefault>
                      <Recipe name="ctrl" isDefault />
                      <Recipe name="chal">
                        {[PUB_STATES_LOWERCASE.USED, PUB_STATES_LOWERCASE.NEW].includes(pagePublicationState) && (
                          <SrpTopIncentives
                            incentives={incentives}
                            isMobile={isMobile}
                            attributes={get(inventoryData, 'attributes')}
                            engineTypes={engineTypes}
                            defaultYear={latestYear}
                            bodyType={bodyType}
                            pagePublicationState={pagePublicationState}
                          />
                        )}
                      </Recipe>
                    </Experiment>
                  )}
                </>
              )
            }
          </FeatureFlag>
        );
      }
      default: {
        return null;
      }
    }
  }
);

SrpTopMessage.displayName = 'SrpTopMessage';

SrpTopMessage.propTypes = {
  selectedFacets: PropTypes.shape({}),
  inventoryData: PropTypes.shape({
    inventories: PropTypes.shape({
      pageNumber: PropTypes.number,
    }),
    attributes: PropTypes.shape({
      vehicle: PropTypes.shape({
        make: PropTypes.shape({
          name: PropTypes.string,
          niceId: PropTypes.string,
        }),
        model: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
      radius: PropTypes.number,
      inventoryType: PropTypes.string,
      lowInventoryLeadForm: PropTypes.bool,
    }),
    seoInfo: PropTypes.shape({
      narrativeWidget: PropTypes.shape({
        title: PropTypes.string,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            heading: PropTypes.string,
            content: PropTypes.arrayOf(PropTypes.string),
          })
        ),
        learnMoreText: PropTypes.string,
      }),
    }),
  }),
  pathName: PropTypes.string,
  pageName: PropTypes.string,
  isNoInventoryPorsche: PropTypes.bool,
  isLocalDeliveryType: PropTypes.bool,
  isExpandedRadiusDueToLowResults: PropTypes.bool,
  isMobile: PropTypes.bool,
  userCapOnePrequalApproved: PropTypes.bool,
  onUpdate: PropTypes.func,
  onRemoveCapOnePrequalParam: PropTypes.func,
};

SrpTopMessage.defaultProps = {
  selectedFacets: null,
  inventoryData: null,
  pathName: null,
  pageName: null,
  isNoInventoryPorsche: false,
  isLocalDeliveryType: false,
  isExpandedRadiusDueToLowResults: false,
  isMobile: false,
  userCapOnePrequalApproved: false,
  onUpdate: noop,
  onRemoveCapOnePrequalParam: noop,
};
