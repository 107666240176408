import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get, isNil } from 'lodash';
import { Link } from 'site-modules/shared/components/link/link';
import { InventoryEntities } from 'client/data/models/inventory';
import { getLinkDataFromInventory, getLinkDataFromVehicle, getLink } from './research-page-link-utils';

/**
 * Research Page link component renders MMY Link or MM link.
 * @param {Object} vehicle
 * @param {Array} inventory
 * @param {Object[]} facets
 * @returns {ReactElement}
 */
export function ResearchPageLink({ vehicle, facets, inventory }) {
  const linkData = isNil(vehicle) ? getLinkDataFromInventory({ facets, inventory }) : getLinkDataFromVehicle(vehicle);

  const makeName = get(linkData, 'make.name');
  const makeSlug = get(linkData, 'make.slug');
  const modelName = get(linkData, 'model.name');
  const modelSlug = get(linkData, 'model.slug');
  const year = get(linkData, 'year', '');
  const isUsedModel = get(linkData, 'isUsedModel');

  if (!modelName) {
    return null;
  }

  const link = getLink({ makeSlug, modelSlug, year, isUsedModel });

  return (
    <div className={classNames('research-page-link size-16 text-gray-darker')}>
      <Link to={link} data-tracking-id="reviews_specs" data-tracking-value={`${year} ${makeName} ${modelName}`.trim()}>
        <span className="icon-arrow-left4 size-14 text-primary-darker" aria-hidden /> {isUsedModel ? year : ''}{' '}
        {modelName} Reviews &#38; Specs
      </Link>
    </div>
  );
}

ResearchPageLink.propTypes = {
  vehicle: PropTypes.shape({}),
  inventory: PropTypes.arrayOf(PropTypes.object),
  facets: InventoryEntities.Facets,
};

ResearchPageLink.defaultProps = {
  vehicle: null,
  inventory: [],
  facets: [],
};
