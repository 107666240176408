import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'site-modules/shared/components/link/link';
import { LinkWidgetTable } from 'site-modules/shared/components/link-widget-table/link-widget-table';

const CAR_LEASE_LINK = '/calculators/car-lease.html';
const CALCULATE_LEASE_PAYMENT_LINK = '/car-leasing/calculate-your-own-lease-payment.html';
const MOBILE_HEADERS = {
  'Est. Monthly Payment': 'Est. Mo. Payment',
};

/**
 * Transforms data to mobile view.
 * @param {object} data
 */
function transformDataToMobile(data) {
  const mobileHeaders = data.headers.map(header => MOBILE_HEADERS[header] || header);
  return {
    ...data,
    headers: mobileHeaders,
  };
}

export const LeaseDealsLinkWidgetTable = memo(({ data, make, isMobile, className }) => {
  const mobileData = isMobile && transformDataToMobile(data);
  return (
    <LinkWidgetTable data={mobileData || data} className={className}>
      <Fragment>
        Edmunds has detailed leasing information for leasing a {make} in your area. If you would like to learn more
        about how much car you can afford, check out the Edmunds&nbsp;
        <Link to={CAR_LEASE_LINK}>car lease calculator</Link> to help you crunch the numbers to fit within your budget.
        If you are interested in learning more about car leasing formulas, read our guide on&nbsp;
        <Link to={CALCULATE_LEASE_PAYMENT_LINK}>how to calculate a car lease payment</Link>.
      </Fragment>
    </LinkWidgetTable>
  );
});

LeaseDealsLinkWidgetTable.displayName = 'LeaseDealsLinkWidgetTable';

LeaseDealsLinkWidgetTable.propTypes = {
  data: PropTypes.shape({}).isRequired,
  make: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  className: PropTypes.string,
};

LeaseDealsLinkWidgetTable.defaultProps = {
  isMobile: false,
  className: '',
};
