import { get } from 'lodash';
/* Utils */
import { getDigitalRetailCta } from 'site-modules/shared/utils/inventory/contact-options';

/* Constants */
import { CTA_TYPE } from 'site-modules/shared/constants/inventory/lead-form';

/**
 * Defines if first vehicles have digital retail cta or base cta
 * @param inventoryData
 * @param isDealerSrp
 * @returns {boolean}
 */
export const isFirstVehiclesEligible = ({ inventoryData, isDealerSrp }) => {
  const inventory = get(inventoryData, 'inventories.results', []);

  return inventory.slice(0, 2).some(inv => {
    const ctas = getDigitalRetailCta({
      ctas: get(inv, 'computedDisplayInfo.ctas', []),
      isSrp: true,
      isDealerSrp,
      inventory: inv,
    });

    return ctas.some(cta => [CTA_TYPE.USED.BASE, CTA_TYPE.NEW.BASE].includes(cta));
  });
};
