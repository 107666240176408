import { useEffect, useRef } from 'react';
import { ModalTrapFocusHelper } from 'client/utils/modal-trap-focus-helper';

export const useModalFocusTrap = containerRef => {
  const modalTrapFocusHelper = useRef(new ModalTrapFocusHelper());

  useEffect(() => {
    const modalTrapFocusHelperNode = modalTrapFocusHelper.current;
    modalTrapFocusHelperNode.init(() => containerRef.current);

    return () => {
      modalTrapFocusHelperNode.clear();
    };
  }, [containerRef]);
};
