import { get } from 'lodash';

/**
 * Generate the placeholder with active YMMT.
 * @param {object} suggestedFacets it is the suggested facets for selected YMMT.
 * @returns {array}
 */
export function getAdditionalSuggestionsPlaceholders(suggestedFacets) {
  const additionalSuggestions = get(suggestedFacets, '[0].values', []);
  const createdSuggestions = [
    `${get(additionalSuggestions, '[0].name', '')}, ${get(additionalSuggestions, '[1].name', '')}`.trim(),
    `${get(additionalSuggestions, '[2].name', '')}, ${get(additionalSuggestions, '[3].name', '')}`.trim(),
    `${get(additionalSuggestions, '[4].name', '')}, ${get(additionalSuggestions, '[5].name', '')}`.trim(),
  ];
  return createdSuggestions.map(suggestion => (suggestion === ',' ? '' : suggestion));
}
