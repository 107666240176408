import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, map, isEmpty } from 'lodash';
import Table from 'reactstrap/lib/Table';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';

import './inventory-data-table.scss';

function getStylesDataFromInventory(inventory) {
  const result = {};

  inventory.forEach(vin => {
    const styleInfo = get(vin, 'vehicleInfo.styleInfo', {});
    const year = get(styleInfo, 'year', '');
    const make = get(styleInfo, 'make', '');
    const model = get(styleInfo, 'model', '');
    const style = get(styleInfo, 'style');
    const price = get(vin, 'prices.displayPrice');
    const vehicleName = `${year} ${make} ${model} ${style}`;
    const styleTableData = result[vehicleName];

    if (styleTableData) {
      if (price && (styleTableData.startingPrice > price || !styleTableData.startingPrice)) {
        styleTableData.startingPrice = price;
      }
    } else {
      const fuel = get(styleInfo, 'fuel', {});
      const epaCityMPG = get(fuel, 'epaCityMPG', '');
      const epaHighwayMPG = get(fuel, 'epaHighwayMPG', '');
      const mpg = epaCityMPG && epaHighwayMPG && `${epaCityMPG} - ${epaHighwayMPG}`;

      const partsInfo = get(vin, 'vehicleInfo.partsInfo', {});
      const cylinders = get(partsInfo, 'cylinders', '');
      const engineType = get(partsInfo, 'engineType', '');
      const engine = cylinders && engineType && `${cylinders}-cylinders (${engineType})`;

      result[vehicleName] = {
        startingPrice: price,
        mpg,
        engine,
      };
    }
  });

  return result;
}

export const InventoryDataTable = memo(({ inventory, className }) => {
  const tableData = getStylesDataFromInventory(inventory);

  if (isEmpty(tableData)) return null;

  return (
    <Table responsive borderless className={classnames('inventory-data-table text-gray-darker size-16', className)}>
      <thead>
        <tr>
          <th scope="col">Trim</th>
          <th scope="col">MPG</th>
          <th scope="col">Engine</th>
          <th scope="col">Starting Price</th>
        </tr>
      </thead>
      <tbody>
        {map(tableData, ({ mpg, engine, startingPrice }, styleName) => (
          <tr key={styleName}>
            <th scope="row" className="font-weight-normal">
              {styleName}
            </th>
            <td>{mpg || 'N/A'}</td>
            <td>{engine || 'N/A'}</td>
            <td>{startingPrice ? formatPriceString(startingPrice) : 'N/A'}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
});

InventoryDataTable.displayName = 'InventoryDataTable';

InventoryDataTable.propTypes = {
  inventory: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
};

InventoryDataTable.defaultProps = {
  inventory: [],
  className: '',
};
