import React from 'react';

import './ico-widget-error.scss';

export const IcoWidgetErrorIcon = () => (
  <svg width={125} height={109} viewBox="0 0 125 109" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.1 3a6 6 0 0 0-10.4 0l-56 97a6 6 0 0 0 5.2 9h112a6 6 0 0 0 5.2-9l-56-97Zm3.4 38.5a8 8 0 1 0-16 0v26.7a8 8 0 0 0 16 0V41.5Zm-8 56a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z"
      fill="#E0E0E0"
    />
  </svg>
);

export const IcoWidgetError = () => (
  <div className="ico-widget-error">
    <IcoWidgetErrorIcon />
    <h2 className="ico-widget-error__title">We&apos;re Sorry</h2>
    <span className="ico-widget-error__description">
      We&apos;re unable to appraise your car at this time. Please try again later.
    </span>
  </div>
);
