const KEYCODE_TAB = 9;
const FOCUSABLE_ELEMENTS = ['a[href]', 'button', 'textarea', 'input', 'select']
  .map(element => `${element}:not([disabled])`)
  .join(', ');

function getDefaultModalContainer() {
  return document.querySelector('.modal.show, .modal-drawer.show');
}

function getFocusableElements(activeModal) {
  return activeModal ? activeModal.querySelectorAll(FOCUSABLE_ELEMENTS) : null;
}

export class ModalTrapFocusHelper {
  init(getModalContainer = getDefaultModalContainer) {
    this.modalContainerProvider = getModalContainer;
    const focusableElements = getFocusableElements(this.modalContainerProvider());
    if (focusableElements) {
      const firstFocusableElement = focusableElements[0];
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
    }
    document.addEventListener('keydown', this.trapFocus);
  }
  clear() {
    document.removeEventListener('keydown', this.trapFocus);
  }
  trapFocus = e => {
    const focusableElements = getFocusableElements(this.modalContainerProvider());

    if (!focusableElements) {
      return;
    }

    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement = focusableElements[focusableElements.length - 1];
    const isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB;
    if (!isTabPressed) {
      return;
    }
    /* shift + tab */
    if (e.shiftKey) {
      if (firstFocusableElement && document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus();
        e.preventDefault();
      }
    } else if (lastFocusableElement && document.activeElement === lastFocusableElement) {
      firstFocusableElement.focus();
      e.preventDefault();
    }
  };
}
