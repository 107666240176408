import { venomHistory } from 'client/utils/history/venom-history';
import { getSrpBrowserQuery } from 'site-modules/shared/utils/inventory/srp-utils';
import { SRP_BASE_PATHNAME } from 'site-modules/shared/constants/inventory/routes-constants';

/**
 * Method is designed specifically for flat URLs (deep links)
 * Accepts selected facets list and applies SEO-friendly URL
 * @param {Object} params All selected facets
 * @param {String} urlPathname E.g. /used-toyota-corolla/
 */
export function changeSrpRoute(params, { urlPathname } = {}) {
  const stringifiedParams = getSrpBrowserQuery(params);
  const url = `${urlPathname || SRP_BASE_PATHNAME}${stringifiedParams ? `?${stringifiedParams}` : ''}`;

  venomHistory.push(url);
}
