import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop, trimEnd, trimStart } from 'lodash';

function truncate({ text, maxTextLength, isFullCollapse }) {
  const isTruncateBecauseOfLength = text.length > maxTextLength;

  let indexToSplit = text.lastIndexOf(' ', maxTextLength);
  while (indexToSplit > 0 && text[indexToSplit - 1].match(/\s/)) {
    indexToSplit -= 1;
  }

  if (isFullCollapse) {
    return {
      textFirstPart: '',
      textSecondPart: text.trim(),
    };
  }

  if (!isTruncateBecauseOfLength || indexToSplit === -1) {
    return {
      textFirstPart: text.trim(),
      textSecondPart: '',
    };
  }

  return {
    textFirstPart: trimStart(text.substring(0, indexToSplit)),
    textSecondPart: trimEnd(text.substring(indexToSplit)),
  };
}

export function TruncatedTextLessCSS({
  text,
  className,
  onTruncateToggle,
  maxTextLength,
  additionalContent,
  alwaysShowAdditionalContent,
  btnClassName,
  indicatorOpenClass,
  indicatorCloseClass,
  btnTextLess,
  btnTextMore,
  btnTextA11y,
  expandTrackingId,
  expandTrackingValue,
  collapseTrackingId,
  collapseTrackingValue,
  ariaIds,
  isFullCollapse,
  isInlineBtn,
  isOpenOnLoad,
}) {
  const [isCollapsed, setCollapsed] = useState(!isOpenOnLoad);

  // if we have additional content to show, we should ALWAYS truncate, unless alwaysShowAdditionalContent is true
  const isTruncateBecauseOfAdditionalContent = !alwaysShowAdditionalContent && !!additionalContent;
  const isTruncateBecauseOfLength = text.length > maxTextLength;
  if (!(isTruncateBecauseOfAdditionalContent || isTruncateBecauseOfLength)) {
    return <div className={className}> {text} </div>;
  }

  const { textFirstPart, textSecondPart } = truncate({ text, maxTextLength, isFullCollapse });

  function toggleCollapse() {
    onTruncateToggle(!isCollapsed);
    setCollapsed(!isCollapsed);
  }

  const getButton = isOpenBtn => {
    const indicatorOpen = indicatorOpenClass ? <span className={indicatorOpenClass} aria-hidden /> : '';
    const indicatorClose = indicatorCloseClass ? <span className={indicatorCloseClass} aria-hidden /> : '';
    const btnId = ariaIds ? `btn-${isOpenBtn}-${ariaIds.replace(/\s+/, '-')}` : null;

    return (
      <button
        className={classnames(btnClassName, {
          body: !isOpenBtn,
          'd-none': (isOpenBtn && !isCollapsed) || (!isOpenBtn && isCollapsed),
        })}
        onClick={toggleCollapse}
        id={btnId}
        aria-expanded={!isCollapsed}
        {...(ariaIds
          ? {
              'aria-labelledby': `${btnId} ${ariaIds}`,
            }
          : {})}
        {...(isOpenBtn && expandTrackingId
          ? {
              'data-tracking-id': expandTrackingId,
              'data-tracking-value': expandTrackingValue,
            }
          : {})}
        {...(!isOpenBtn && collapseTrackingId
          ? {
              'data-tracking-id': collapseTrackingId,
              'data-tracking-value': collapseTrackingValue,
            }
          : {})}
        style={{ lineHeight: '1rem' }}
      >
        <span>
          {isOpenBtn ? btnTextMore : btnTextLess}
          {!!btnTextA11y && <span className="sr-only">{btnTextA11y}</span>}
        </span>
        {isOpenBtn ? indicatorOpen : indicatorClose}
      </button>
    );
  };

  const expandBtn = getButton(true);
  const collapseBtn = getButton(false);

  /* For accessibility it is beneficial to have two buttons, one for hiding and one for showing. */
  return (
    <div className={className}>
      <div>
        {!isFullCollapse && (
          <span>
            {textFirstPart}
            {isCollapsed && <Fragment>&hellip;</Fragment>}
          </span>
        )}
        <span className={classnames({ 'd-none': isCollapsed })}>{textSecondPart}</span>

        {isInlineBtn && (
          <Fragment>
            {expandBtn}
            {collapseBtn}
          </Fragment>
        )}
      </div>
      {!isInlineBtn && (
        <Fragment>
          <div>{expandBtn}</div>
          <div>{collapseBtn}</div>
        </Fragment>
      )}
    </div>
  );
}

TruncatedTextLessCSS.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  onTruncateToggle: PropTypes.func,
  maxTextLength: PropTypes.number,
  additionalContent: PropTypes.node,
  alwaysShowAdditionalContent: PropTypes.bool,
  btnClassName: PropTypes.string,
  indicatorOpenClass: PropTypes.string,
  indicatorCloseClass: PropTypes.string,
  btnTextLess: PropTypes.string,
  btnTextMore: PropTypes.string,
  btnTextA11y: PropTypes.string,
  expandTrackingId: PropTypes.string,
  expandTrackingValue: PropTypes.string,
  collapseTrackingId: PropTypes.string,
  collapseTrackingValue: PropTypes.string,
  ariaIds: PropTypes.string,
  isFullCollapse: PropTypes.bool,
  isInlineBtn: PropTypes.bool,
  isOpenOnLoad: PropTypes.bool,
};

TruncatedTextLessCSS.defaultProps = {
  text: '',
  className: null,
  onTruncateToggle: noop,
  maxTextLength: 85,
  additionalContent: null,
  alwaysShowAdditionalContent: false,
  btnClassName: 'text-gray-darker',
  indicatorOpenClass: null,
  indicatorCloseClass: null,
  btnTextLess: 'Read less',
  btnTextMore: 'Read more',
  btnTextA11y: null,
  expandTrackingId: null,
  expandTrackingValue: null,
  collapseTrackingId: null,
  collapseTrackingValue: null,
  ariaIds: null,
  isFullCollapse: false,
  isInlineBtn: false,
  isOpenOnLoad: false,
};
