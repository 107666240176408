import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { mapKeys, omitBy, isEmpty, noop, get, isNil, trim } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { injectScript } from 'client/utils/inject-script';
import { logger } from 'client/utils/isomorphic-logger';
import {
  DealerIcoWidgetModel,
  buildDealerIcoWidgetConfigurationPath,
  DealerIcoWidgetEntities,
} from 'client/data/models/dealer-ico-widget';
import { Spinner } from 'site-modules/shared/components/spinner/spinner';
import { IcoWidgetError } from 'site-modules/shared/components/dealer-ico-widget/ico-widget-error/ico-widget-error';
import {
  ICO_SCRIPT_SRC,
  ICO_TARGET_CLASS,
  ICO_OFFER_CTA_TYPES,
  ICO_DATASET_KEYS_TRANSFORM_MAP,
  ICO_ON_EDMUNDS_SUPPRESSED_CONFIG_KEYS,
  ICO_ON_EDMUNDS_CREATIVE_IDS,
  ICO_PRODUCT_IDS,
} from 'site-modules/shared/components/dealer-ico-widget/constants';

import './ico-widget.scss';

export async function injectIcoScript(customScriptSrc = ICO_SCRIPT_SRC, setIsWidgetScriptLoaded = noop) {
  try {
    // to prevent starting loading process while the script is still loading
    setIsWidgetScriptLoaded(true);
    await injectScript(customScriptSrc);
    logger('log', 'ICO script injected successfully');
  } catch (e) {
    setIsWidgetScriptLoaded(false);
    throw new Error('ICO script injection error');
  }
}

export function mapDataAttributesFromObjectKeys(obj) {
  return mapKeys(obj, (val, key) => {
    const lowerCaseKey = key.toLowerCase();
    return `data-${ICO_DATASET_KEYS_TRANSFORM_MAP[lowerCaseKey] || lowerCaseKey}`;
  });
}

/**
 *
 * @param obj Full config object with RAW keys & values returned by API
 * @return {Object} Config object -- excluding suppressed configs
 */
export function omitSuppressedConfigs(obj) {
  return omitBy(obj, (val, key) => {
    const lowerCaseKey = key.toLowerCase();
    return ICO_ON_EDMUNDS_SUPPRESSED_CONFIG_KEYS.includes(lowerCaseKey) || isNil(val) || isEmpty(trim(val));
  });
}

const LOG_MESSAGES = {
  INACTIVE_WIDGET: 'ICO is not activated for this dealer. Aborting ICO script injection.',
  API_ERROR: 'ICO configuration loading error. Aborting ICO script injection.',
};

function log(icoWidgetConfig = {}) {
  const isEmptyConfig = isEmpty(icoWidgetConfig);
  if (!isEmptyConfig && icoWidgetConfig.hasError) {
    logger('warn', LOG_MESSAGES.API_ERROR);
  } else if (!isEmptyConfig && !icoWidgetConfig.isActive) {
    logger('warn', LOG_MESSAGES.INACTIVE_WIDGET);
  }
}

export function IcoWidgetUI(props) {
  const {
    dealerId,
    carMaxDealerId,
    multiDealerIds,
    className,
    displayType,
    integration,
    applyToDeal,
    applyToCalculator,
    icoWidgetConfig,
    injectInitializationScriptOnMount,
    setIsWidgetActive,
    showSpinner,
    zipCode,
    notifyWidgetLoaded,
    productId,
    checkWidgetConfiguration,
    suppressLeadFormTracking,
  } = props;

  const widgetRef = useRef(null);
  // to prevent loading the widget script twice
  const [isWidgetScriptLoaded, setIsWidgetScriptLoaded] = useState(false);
  const [shouldForceActive, setShouldForceActive] = useState(false);

  useEffect(() => {
    if (!widgetRef?.current) {
      return noop;
    }
    const observer = new MutationObserver(() => {
      // child content changed (such as spinner removed):
      notifyWidgetLoaded();
      observer.disconnect();
    });
    observer.observe(widgetRef.current, { childList: true });
    return () => {
      observer.disconnect();
    };
  }, [notifyWidgetLoaded, widgetRef]);

  useEffect(() => {
    setShouldForceActive(productId === ICO_PRODUCT_IDS.ICO_VENOM);
  }, [productId]);

  useEffect(() => {
    const hasError = get(icoWidgetConfig, 'hasError', false);
    const isWidgetActive = shouldForceActive || !checkWidgetConfiguration || get(icoWidgetConfig, 'isActive', false);
    if (shouldForceActive || !checkWidgetConfiguration || !isEmpty(icoWidgetConfig)) {
      setIsWidgetActive(isWidgetActive);
    }
    const isValidWidgetConfig =
      shouldForceActive || !checkWidgetConfiguration || (!isEmpty(icoWidgetConfig) && !hasError && isWidgetActive);
    if (isWidgetScriptLoaded || !injectInitializationScriptOnMount || !isValidWidgetConfig) {
      log(icoWidgetConfig);
      return;
    }

    (async () => {
      await injectIcoScript(undefined, setIsWidgetScriptLoaded);
    })();
  }, [
    checkWidgetConfiguration,
    icoWidgetConfig,
    injectInitializationScriptOnMount,
    isWidgetScriptLoaded,
    setIsWidgetActive,
    shouldForceActive,
  ]);

  const renderSpinner = () => (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <em>Loading Instant Cash Offer widget...</em>
      <Spinner size={50} thickness={8} color="primary" className="mt-1" />
    </div>
  );

  const isActive = shouldForceActive || !checkWidgetConfiguration || get(icoWidgetConfig, 'isActive', false);
  const loadParams = checkWidgetConfiguration ? get(icoWidgetConfig, 'loadParams', {}) : {};

  if (!isEmpty(icoWidgetConfig) && !isActive) {
    notifyWidgetLoaded();
    return <IcoWidgetError />;
  }

  return (
    <div>
      <div
        ref={widgetRef}
        data-tracking-parent={ICO_ON_EDMUNDS_CREATIVE_IDS.VEHICLE_LOOKUP}
        className={classnames(ICO_TARGET_CLASS, className)}
        /* Apply config override attributes that especially are usually/have use cases to be:
         - Hardcoded
         - Or obtainable from host Venom page Redux model
         - Or not available via Widget Store
       */
        data-dealerid={dealerId}
        data-display={displayType}
        {...(carMaxDealerId ? { 'data-carmaxdealerid': carMaxDealerId } : {})}
        {...(multiDealerIds ? { 'data-multidealerids': multiDealerIds } : {})}
        {...(suppressLeadFormTracking ? { 'data-suppressleadformtracking': 'true' } : {})}
        {...(applyToDeal ? { [`data-${ICO_OFFER_CTA_TYPES.APPLY_TO_DEAL}`]: 'true' } : {})}
        {...(applyToCalculator ? { 'data-cta': ICO_OFFER_CTA_TYPES.APPLY_TO_CALCULATOR } : {})}
        {...(integration ? { 'data-integration': integration } : {})}
        {...(zipCode ? { 'data-zipcode': zipCode } : {})}
        {...(productId ? { 'data-productid': productId } : {})}
        /* Apply the rest of config overrides from Widget Store: */
        {...mapDataAttributesFromObjectKeys(omitSuppressedConfigs(loadParams))}
      >
        {showSpinner && renderSpinner()}
      </div>
    </div>
  );
}

IcoWidgetUI.propTypes = {
  dealerId: PropTypes.string.isRequired,
  carMaxDealerId: PropTypes.number,
  multiDealerIds: PropTypes.string,
  className: PropTypes.string,
  displayType: PropTypes.string,
  zipCode: PropTypes.string,
  integration: PropTypes.string,
  applyToDeal: PropTypes.bool,
  applyToCalculator: PropTypes.bool,
  icoWidgetConfig: DealerIcoWidgetEntities.Configs,
  injectInitializationScriptOnMount: PropTypes.bool,
  // Bubbles up active status to callers, to save them from having to recheck the configs:
  setIsWidgetActive: PropTypes.func,
  showSpinner: PropTypes.bool,
  notifyWidgetLoaded: PropTypes.func,
  productId: PropTypes.string,
  checkWidgetConfiguration: PropTypes.bool,
  suppressLeadFormTracking: PropTypes.bool,
};

IcoWidgetUI.defaultProps = {
  carMaxDealerId: undefined,
  multiDealerIds: undefined,
  className: '',
  displayType: undefined,
  integration: undefined,
  zipCode: undefined,
  applyToDeal: false,
  applyToCalculator: false,
  icoWidgetConfig: {},
  injectInitializationScriptOnMount: false,
  setIsWidgetActive: noop,
  showSpinner: true,
  notifyWidgetLoaded: noop,
  productId: undefined,
  checkWidgetConfiguration: true,
  suppressLeadFormTracking: false,
};

export const stateToPropsConfig = {
  icoWidgetConfig: bindToPath(
    ({ dealerId }) => buildDealerIcoWidgetConfigurationPath({ rooftopId: dealerId }),
    DealerIcoWidgetModel
  ),
};

export const IcoWidget = connect()(connectToModel(IcoWidgetUI, stateToPropsConfig));
