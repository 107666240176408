import { getDealershipStateCityNameUrl } from 'site-modules/shared/utils/dealership-link-constructor';

/**
 * Generates a link to the dealership page
 *
 * @param {Boolean} national
 * @param {Object} attributes
 * @returns {String}
 */
export function getDealershipPageUrl(attributes, national = false) {
  const dealer = attributes.dealerInfo;
  if (dealer) {
    const { stateName: state, city } = dealer;
    const DDP = getDealershipStateCityNameUrl({
      state,
      city,
      dealership: dealer.rooftopLogicalName,
    });

    return `${DDP}${national ? '?national=true' : ''}`;
  }

  return null;
}
