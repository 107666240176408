import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { WRAPPER_TYPES } from 'site-modules/shared/components/faq/constants';
import { Faq } from 'site-modules/shared/components/faq/faq';

import './faq-module.scss';

export const FaqModule = memo(({ className, faqApiData }) => {
  if (!faqApiData) {
    return null;
  }

  const { items, openingText, title } = faqApiData;

  return (
    <section className={classnames('faq-module text-gray-darker', className)}>
      <h2 className="heading-3">{title}</h2>
      {openingText && <p className="size-16">{openingText}</p>}
      <Faq title={null} entries={items} collapseToggleSrExplanation={title} wrapperType={WRAPPER_TYPES.collapse} />
    </section>
  );
});

FaqModule.displayName = 'FaqModule';

FaqModule.propTypes = {
  className: PropTypes.string,
  faqApiData: PropTypes.shape({
    title: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        question: PropTypes.string,
        answer: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    learnMoreText: PropTypes.string,
    openingText: PropTypes.string,
  }),
};

FaqModule.defaultProps = {
  className: '',
  faqApiData: null,
};
