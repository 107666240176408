import React, { Fragment, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { generateSEOTitle, SearchFilterSeoObjectEntities } from 'site-modules/shared/utils/inventory/srp-seo-utils';
import { getYearMakeModelTrimPlaceholder } from 'site-modules/inventory/utils/get-year-make-model-trim-placeholder';
import { getAdditionalSuggestionsPlaceholders } from 'site-modules/inventory/utils/get-additional-suggestions-placeholders';
import { InventoryEntities } from 'client/data/models/inventory';
import { CorePageParams } from 'site-modules/shared/utils/core-page/params';
import { AnimatedSticky } from 'site-modules/shared/components/animated-sticky/animated-sticky';
import { SEOTitleLink } from 'site-modules/inventory/components/srp-search/srp-search-title';
import { transformSelectedFacetsForDisplaying } from 'site-modules/shared/utils/inventory/search-filter';
import { SPR_SCROLL_TO_TOP_ANCHOR } from 'site-modules/shared/constants/inventory/routes-constants';
import { SrpQueryInput } from 'site-modules/inventory/components/srp-query-input/srp-query-input';
import { FilterButton } from 'site-modules/shared/components/inventory/filters-button/filters-button';
import { TruncatedTextLessCSS } from 'client/site-modules/inventory/components/truncated-text-less-css/truncated-text-less-css';
import { SrpCoreContent } from 'site-modules/inventory/components/srp-core-content/srp-core-content';

import './usurp-sticky-header-semantic-search.scss';

function UsurpStickyHeaderSemanticSearchUI({
  seoData,
  visitorLocation,
  attributes,
  onFiltersBtnClick,
  isNational,
  isDealerSrp,
  filteringByFacets,
  isMobile,
  suggestedFacets,
  firstSentence,
  selectedFacets,
  disableLlm,
  withCoreReviewSection,
  vehicleParams,
  isVinPreview,
}) {
  const { city, stateCode } = visitorLocation;
  const { generalPart, locationPart, finalPart } = generateSEOTitle({
    seoData,
    visitorLocation,
    isMeta: false,
    returnParts: true,
    isUsedCoreComboTitle: withCoreReviewSection,
  });
  const dealerId = selectedFacets?.rooftopId?.[0];
  const defaultLocationPart = isNational ? 'Near Me' : `Near ${city}, ${stateCode}`;

  const activeFilters = transformSelectedFacetsForDisplaying(filteringByFacets);
  const activeFiltersCount = activeFilters.length;
  const YMMT = getYearMakeModelTrimPlaceholder(activeFilters);
  const suggestedFilters = getAdditionalSuggestionsPlaceholders(suggestedFacets);

  const renderStickyFilters = useCallback(
    () => (
      <FilterButton
        onFiltersBtnClick={onFiltersBtnClick}
        activeFiltersCount={activeFiltersCount}
        classes="d-lg-none w-100 justify-content-center"
      />
    ),
    [activeFiltersCount, onFiltersBtnClick]
  );
  const titlePrefix = isVinPreview ? 'Other ' : '';

  return (
    <div className="usurp-sticky-header-semantic-search mb-1_5" id={SPR_SCROLL_TO_TOP_ANCHOR}>
      <AnimatedSticky stickyFragment={renderStickyFilters} stickyClassName="py-0_75" disableSticky={!isMobile}>
        {!(withCoreReviewSection && isMobile) && (
          <div className="mb-1">
            <h1
              className={classnames('d-inline mb-0', {
                'heading-5': isMobile,
                'heading-3': !isMobile,
                'text-white': !isVinPreview,
                'text-cool-gray-10': isVinPreview,
              })}
            >
              {isDealerSrp && (
                <SEOTitleLink
                  attributes={attributes}
                  title={generalPart}
                  isNationalSrp={isNational}
                  arrowClasses="bg-transparent medium"
                />
              )}
              {!isDealerSrp && (
                <Fragment>
                  {titlePrefix}
                  {generalPart}{' '}
                  {Boolean(locationPart) &&
                    (isMobile ? (
                      <button
                        onClick={onFiltersBtnClick}
                        type="button"
                        className={classnames('open-filters-btn heading-part font-weight-bold p-0', {
                          'text-white': !isVinPreview,
                          'text-cool-gray-10': isVinPreview,
                        })}
                      >
                        <span className="text-underline">{locationPart.trim()}</span>
                      </button>
                    ) : (
                      locationPart.trim()
                    ))}{' '}
                  {finalPart}
                </Fragment>
              )}
            </h1>
            {!locationPart && isMobile && !isDealerSrp && (
              <button
                onClick={onFiltersBtnClick}
                type="button"
                className={classnames('open-filters-btn heading-part font-weight-bold d-lg-none p-0 d-inline', {
                  'text-white': !isVinPreview,
                  'text-cool-gray-10': isVinPreview,
                })}
              >
                {' '}
                <span className="toggle-text">{defaultLocationPart}</span>
              </button>
            )}
            {!!firstSentence && (
              <TruncatedTextLessCSS
                text={firstSentence}
                className={`mt-0_5 text-white text-shadow-contrast ${isMobile ? 'size-14' : 'size-16'}`}
                maxTextLength={isMobile ? 80 : 240}
                isInlineBtn
                btnClassName={`text-underline text-white text-shadow-contrast bg-transparent border-0 ${
                  isMobile ? 'size-14' : 'size-16'
                }`}
                btnTextLess="less"
                btnTextMore="more"
                btnTextA11y="of the page introduction"
              />
            )}
          </div>
        )}

        {withCoreReviewSection && isMobile && (
          <SrpCoreContent vehicleParams={vehicleParams} isMobile pageHeaderText={generalPart} />
        )}
        <SrpQueryInput
          onFiltersBtnClick={onFiltersBtnClick}
          activeFiltersCount={activeFiltersCount}
          isMobile={isMobile}
          YMMTPlaceholder={YMMT}
          dynamicPlaceholderSuggestions={suggestedFilters}
          dealerId={dealerId}
          disableLlm={disableLlm}
        />
      </AnimatedSticky>
    </div>
  );
}

UsurpStickyHeaderSemanticSearchUI.propTypes = {
  seoData: SearchFilterSeoObjectEntities.isRequired,
  visitorLocation: PropTypes.shape({
    city: PropTypes.string,
    stateCode: PropTypes.string,
  }).isRequired,
  onFiltersBtnClick: PropTypes.func.isRequired,
  isNational: PropTypes.bool,
  isDealerSrp: PropTypes.bool,
  attributes: InventoryEntities.Attributes,
  filteringByFacets: PropTypes.arrayOf(PropTypes.shape({})),
  isMobile: PropTypes.bool,
  suggestedFacets: InventoryEntities.Facets,
  firstSentence: PropTypes.string,
  selectedFacets: PropTypes.shape({}),
  disableLlm: PropTypes.bool,
  withCoreReviewSection: PropTypes.bool,
  vehicleParams: CorePageParams,
  isVinPreview: PropTypes.bool,
};

UsurpStickyHeaderSemanticSearchUI.defaultProps = {
  isNational: false,
  isDealerSrp: false,
  attributes: {},
  filteringByFacets: [],
  isMobile: false,
  suggestedFacets: null,
  firstSentence: '',
  selectedFacets: {},
  disableLlm: false,
  withCoreReviewSection: false,
  vehicleParams: null,
  isVinPreview: false,
};

export const UsurpStickyHeaderSemanticSearch = memo(UsurpStickyHeaderSemanticSearchUI);
