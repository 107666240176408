import React from 'react';
import PropTypes from 'prop-types';
import Table from 'reactstrap/lib/Table';
import { getPriceStringV2 } from 'client/site-modules/shared/utils/price-utils';
import {
  TRADE_IN_VALUE,
  PRIVATE_PARTY_VALUE,
  DEALER_RETAIL_VALUE,
} from 'site-modules/shared/constants/appraisal/appraisal';

/**
 * Renders estimated values table.
 * @param {object[]} conditionValues
 * @param {String} styleName
 * @returns {ReactElement}
 */
export function EstimatedValuesTable({ conditionValues, styleName, withCaption }) {
  return (
    <Table className="estimated-values-table rounded text-gray-darker mb-0_5 mt-1_5">
      {withCaption && <caption className="text-gray-darker size-16 mb-0_25">{styleName} with no options</caption>}
      <thead>
        <tr>
          <th>Condition</th>
          <th className="text-right">Trade-In</th>
          <th className="text-right">Private Party</th>
          <th className="text-right">Dealer Retail</th>
        </tr>
      </thead>
      <tbody>
        {conditionValues.map(condition => {
          const { name } = condition;
          const tradeInValue = getPriceStringV2(condition[TRADE_IN_VALUE]);
          const privatePartyValue = getPriceStringV2(condition[PRIVATE_PARTY_VALUE]);
          const dealerRetailValue = getPriceStringV2(condition[DEALER_RETAIL_VALUE]);

          return (
            <tr key={name}>
              <th scope="row" className="text-capitalize font-weight-normal">
                {name}
              </th>
              <td className="text-right">{tradeInValue}</td>
              <td className="text-right">{privatePartyValue}</td>
              <td className="text-right">{dealerRetailValue}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

EstimatedValuesTable.propTypes = {
  conditionValues: PropTypes.arrayOf(PropTypes.shape({})),
  withCaption: PropTypes.bool,
  styleName: PropTypes.string,
};

EstimatedValuesTable.defaultProps = {
  conditionValues: [],
  withCaption: true,
  styleName: '',
};
